import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {map, Observable} from "rxjs";
import {ConnectionType} from "@flowmaps/flowmaps-typescriptmodels";
import {filterByTermArray} from "../../../../common/utils";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {
    ContractDetailsModalComponent,
    ContractDetailsModalComponentData
} from "../contract-details-modal/contract-details-modal.component";

@Component({
    selector: 'app-contracts-overview',
    templateUrl: './contracts-overview.component.html',
    styleUrls: ['./contracts-overview.component.scss']
})
@Handler()
export class ContractsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;

    term: string;
    query: Observable<ContractEntity[]>;
    data: ContractEntity[] = [];
    selectedConnectionTypes: string[] = [];

    ngAfterViewInit() {
        this.executeQuery();
    }

    executeQuery = () => {
        this.query = this.subscribeTo("getContracts")
            .pipe(map((o: ContractEntity[]) => o.filter(filterByTermArray([this.term], ["organisation"]))))
            .pipe(map(o => o.filter(c => !this.selectedConnectionTypes.length || this.selectedConnectionTypes.includes(c.contract.contractData.connectionType))));
    }

    clearFilter = () => {
        this.selectedConnectionTypes = [];
        this.executeQuery();
    }

    toggleConnectionType = (event: Event): void => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        if (target.checked) {
            this.selectedConnectionTypes.push(value);
        } else {
            const index = this.selectedConnectionTypes.indexOf(value);
            if (index >= 0) {
                this.selectedConnectionTypes.splice(index, 1);
            }
        }
        this.executeQuery();
    }

    addContract = () => {
        this.openModal(ContractDetailsModalComponent, <ContractDetailsModalComponentData>{
            contract: {
                contract: {
                    contractData: {
                        name: '',
                        connectionType: null,
                    },
                    periods: [],
                    defaultContract: false,
                    contractId: "",
                },
                organisation: {
                    organisationId: null,
                    info: {},
                }
            } as ContractEntity
        });
    }

    trackByForRecord = (index: number, record: ContractEntity) => record.contract.contractId;

    trackByConnectionType = (index: number, record: ConnectionType) => record;
}
