import {
    AggregatedDataPoint,
    Connection,
    ContractMeasurementResult,
    DataType,
    DayOfWeekMeasurementsResult,
    GetMeasurements,
    MeasurementsResult,
    Organisation,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import moment from "moment";
import {interpolate, localTimeFormat, lodash} from "../common/utils";
import {DashboardContext, DateTimeSlot} from "../views/dashboard/dashboard.context";
import {AppContext, CompletenessInfo} from "../app-context";
import timeslots from "../resources/timeslots.json";
import {EventEmitter} from "@angular/core";
import {combineLatest, map, Observable, Subject} from "rxjs";
import {ChartCompare, DashboardInfo} from "../views/dashboard/dashboard.types";
import {SourceInfo, SourcesProvider} from "./source-providers/sources-provider";
import {OrganisationProvider} from "./source-providers/organisation-provider";
import {DateFieldRange, MomentDateFieldRange} from "../common/date/date-range/date-field-range";
import {DateRangeUtils} from "../common/date/date-range/date-range.utils";
import {DatePickerRange} from "../common/date/date-picker/date-picker.component";
import {cloneDeep} from "lodash";
import {EntityType} from "../handlers/entity";
import {ChartUtilsService} from "../views/charts/chart-utils.service";
import {sendQuery} from "../common/app-common-utils";
import {MeterViewEntity} from "../handlers/meter-views-standalone-handler";
import {TranslateDirective} from "../common/utils/translate.directive";
import {MeasurementDataset} from "../views/charts/base-measurement-chart";

export abstract class MeasurementsDataProvider<R> {
    private static timeSlots = timeslots as TimeSlots;

    info: DashboardInfo = {};
    dashboardData: DashboardData = {
        labels: [],
        currentData: {
            measurements: {},
            estimatedMeasurements: {}
        },
        previousData: {
            measurements: {},
            estimatedMeasurements: {}
        }
    };
    timeChange = new EventEmitter<DashboardTime>();
    notifier = new Subject();
    dashboardHistory: DashboardTime[] = [];
    selectedSources: SourceInfo[] = [];
    sourceProvider: SourcesProvider<any> = new OrganisationProvider();
    chartUtils: ChartUtilsService;
    ranges: MomentDateFieldRange[];
    groupByEntityIdEnabled: () => boolean = () => false;
    sourceRequired: () => boolean = () => false;
    hasData: () => boolean = () => !lodash.isEmpty(this.dashboardData?.currentData.measurements);

    allCollected: { dateTime?: boolean, sources?: boolean } = {
        dateTime: false,
        sources: false
    }

    private sourcesUpdated: EventEmitter<SourceInfo[]> = new EventEmitter<SourceInfo[]>();
    private dataChanged = new EventEmitter<DashboardData>();

    abstract createChartData(data: R, completeness: CompletenessInfo[], organisations: Organisation[], dateRange: TimeRange, stack?: string, comparedYear?: number): ChartDataPerMeasurement;
    abstract copy(): MeasurementsDataProvider<any>;

    constructor(chartUtils: ChartUtilsService, info?: DashboardInfo, sources?: SourceInfo[]) {
        this.chartUtils = chartUtils;
        this.info = info;
        if (info) {
            this.sourceProvider.selectedSources = info.sources;
        }
        this.sourceProvider.dataCollected.subscribe(d => {
            const selection = this.sourceProvider.getSelection();
            this.sourceChanged(sources || (selection.length > 0 ? selection : undefined));
        });
    }

    private get sourcesAndDateTimeCollected() {
        return this.allCollected.dateTime && this.allCollected.sources;
    }

    timeChanged(time: DashboardTime) {
        this.info.timeRange = time;
        this.info.resolution = time.resolution;
        this.info.predefinedTimeRange = time.label;
        this.timeChange.next(time);
        this.allCollected.dateTime = true;
        if (this.sourcesAndDateTimeCollected) {
            this.getData();
        }
    }

    sourceChanged(sources: SourceInfo[]) {
        const allCollected = this.sourcesAndDateTimeCollected;
        if (this.sourceRequired() && !sources) {
            return;
        }
        this.allCollected.sources = true;
        if (lodash.isEqual(this.selectedSources, sources)) {
            if (!allCollected && this.sourcesAndDateTimeCollected) {
                this.getData();
            }
            return;
        }
        this.selectedSources = sources || [];
        if (this.sourcesAndDateTimeCollected) {
            this.getData();
        }
        this.sourcesUpdated.emit(sources);
    }

    subscribeToSourceUpdates = (callback: (s: SourceInfo[]) => void) => {
        if (!lodash.isEmpty(this.selectedSources)) {
            callback(this.selectedSources);
        }
        this.sourcesUpdated.subscribe(callback);
    }

    subscribeToData = (callback: (d: DashboardData) => void) => {
        if (this.hasData()) {
            callback(this.dashboardData);
        }
        this.dataChanged.subscribe(callback);
    }

    getData() {
        const timeRange = this.getDateTimeRange();
        return combineLatest([this.getDataForRange(timeRange), this.getCompleteness(timeRange), sendQuery("getOrganisations")])
            .subscribe(result => {
                this.dashboardData.currentData = this.createChartData(result[0], result[1], result[2], timeRange);
                this.dataChanged.emit(this.dashboardData);
            });
    }

    getDataForRange(timeRange: TimeRange): Observable<R> {
        return sendQuery("com.flowmaps.api.measurements.GetMeasurements", <GetMeasurements>{
            timeRange: timeRange,
            sources: this.sourceProvider.sourceSelectionAfterCleanup(),
            resolution: this.info.resolution,
            unrounded: ![TimeResolution.year, TimeResolution.month].includes(this.info.resolution)
        });
    }

    getCompleteness = (timeRange: TimeRange): Observable<CompletenessInfo[]> => {
        const emptyConnections = this.sourceProvider.getAllSourcesByType(EntityType.connection)
            .map(s => s.source.connection as Connection)
            .filter(s => !s.meters.length);
        return sendQuery("getPrimaryMeterViews", this.sourceProvider.sourceSelectionAfterCleanup())
            .pipe(map((m: MeterViewEntity[]) => AppContext.computeCompletenessPerTimeRange(m, timeRange, emptyConnections)));
    };

    private groupByTimeFrames(data: ChartSimpleData[], dateRange: TimeRange, selectedResolution: TimeResolution, aggregationMethod: (data: number[]) => number = lodash.sum): GroupedData {
        const slots = MeasurementsDataProvider.getSlots(dateRange, selectedResolution);
        return {
            labels: slots.map(s => s.label),
            data: MeasurementsDataProvider.appendDataToSlots(data, slots).map(e => aggregationMethod(e.values))
        };
    }

    static getSlots(dateRange: TimeRange, selectedResolution: TimeResolution) {
        const startDate = moment(dateRange.start);
        const endDate = moment(dateRange.end);
        const foundSlot = MeasurementsDataProvider.timeSlots[selectedResolution];
        return lodash.range(0, endDate.diff(startDate, foundSlot.unit, false), foundSlot.amount).map(() => {
            const startTime = startDate.valueOf();
            const label = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getFormat(foundSlot.unit));
            const header = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getTableHeaderFormat(foundSlot.unit));
            const endTime = startDate.add(foundSlot.amount, foundSlot.unit).valueOf();

            return <Slot>{
                startTime: startTime,
                endTime: endTime,
                label: label,
                tableHeader: header,
                values: []
            }
        });
    }

    private getDateTimeRange = (): TimeRange => AppContext.timeRangeToQuery(this.info.timeRange);

    getAllSelectedTimeRange() {
        return {
            start: moment().subtract(DashboardContext.maximumYears, "year").startOf("year").format(localTimeFormat),
            end: moment().add(1, "year").startOf("year").format(localTimeFormat)
        };
    }

    getComparedYear(compare: ChartCompare) {
        return compare.relative
            ? moment(this.info.timeRange.start).subtract(compare.comparedYear, 'year').year()
            : compare.comparedYear;
    }

    getForYear(year: number): TimeRange {
        const timeRange = this.getDateTimeRange();
        const time = {
            start: moment(timeRange.start),
            end: moment(timeRange.end)
        };
        const yearsAgo = time.start.year() - year;
        return {
            start: time.start.subtract(yearsAgo, "year").startOf(this.info.resolution).format(localTimeFormat),
            end: time.end.subtract(yearsAgo, "year").startOf(this.info.resolution).format(localTimeFormat)
        }
    }

    getPreviousDateTimeRange(): DateFieldRange {
        let {time, resolution, difference} = this.calculateDifference();
        const range: DatePickerRange = {
            start: time.start.clone().subtract(difference, resolution),
            end: time.start
        };
        const label = DateRangeUtils.getRangeLabel(range, this.ranges);
        return {
            start: range.start.format(localTimeFormat),
            end: range.end.format(localTimeFormat),
            label: label
        }
    }

    getNextDateTimeRange(): DateFieldRange {
        let {time, resolution, difference} = this.calculateDifference();
        const range: DatePickerRange = {
            start: time.end.clone(),
            end: time.end.clone().add(difference, resolution)
        }
        const label = DateRangeUtils.getRangeLabel(range, this.ranges);
        return {
            start: range.start.format(localTimeFormat),
            end: range.end.format(localTimeFormat),
            label: label
        }
    }

    private calculateDifference() {
        const timeRange = this.getDateTimeRange();
        const time = {
            start: moment(timeRange.start),
            end: moment(timeRange.end)
        };
        let resolution = this.getResolutionHigher(this.info.resolution);
        let difference = time.end.diff(time.start, resolution, true);
        if (!lodash.inRange(difference, 0.95, 1.05)) {
            difference = time.end.diff(time.start, this.info.resolution, true);
            resolution = this.info.resolution;
        }
        return {time, resolution, difference};
    }

    private getResolutionHigher(resolution: TimeResolution): TimeResolution {
        switch (resolution) {
            case TimeResolution.year:
                return TimeResolution.year;
            case TimeResolution.month:
                return TimeResolution.year;
            case TimeResolution.day:
                return TimeResolution.month;
            case TimeResolution.hour:
            case TimeResolution.minute:
                return TimeResolution.day;
        }
    }

    protected groupDataByMeasurement(data: MeasurementsResult[], dateRange: TimeRange, stack: string, comparedYear: number, estimated: boolean, source: (m: MeasurementsResult) => { [P in DataType]?: AggregatedDataPoint[] }): { [P in DataType]?: MeasurementDataset[] } {
        const measurements: { [P in DataType]?: MeasurementDataset[] } = {};
        data.forEach((m, i) => {
            Object.entries(source(m)).forEach((e) => {
                const measurementType = e[0] as DataType;
                if (!measurements[measurementType]) {
                    measurements[measurementType] = [];
                }
                let dataset: MeasurementDataset;

                if (this.groupByEntityIdEnabled()) {
                    const entities = this.entitiesWithDataType(measurementType, data);
                    const color = entities.length > 1 ?
                        interpolate(entities.indexOf(m.entityId) + 1, 0, entities.length, -0.5, 0.5) : 0;
                    dataset = this.createChartDatasetForEntity(e[1], measurementType, dateRange, stack, m.entityId, color, comparedYear, estimated);
                } else {
                    dataset = this.createChartDataset(e[1], measurementType, dateRange, stack, comparedYear, AppContext.measurementName(measurementType), 0, estimated);
                }
                if (dataset) {
                    measurements[measurementType].push(dataset);
                }
            });
        });
        return measurements;
    }

    private entitiesWithDataType = (measurementType: DataType, data: MeasurementsResult[]): string[] =>
        data.filter(d => d.measurements[measurementType])
            .filter(d => d.measurements[measurementType].some(r => r.value !== 0))
            .map(d => d.entityId);

    private createChartDatasetForEntity(data: AggregatedDataPoint[], measurementType: DataType, dateRange: TimeRange, stack: string, entityId: string, color: number, lastYearNumber?: number, estimated?: boolean): MeasurementDataset {
        const chartDataset = this.createChartDataset(data, measurementType, dateRange, stack,
            lastYearNumber, `${AppContext.measurementName(measurementType)} - ${this.sourceProvider.findById(entityId)?.info.name}`, color, estimated);
        chartDataset.entityId = entityId;
        chartDataset.dataset.order += color;
        return chartDataset.dataset.data.every(d => d === 0) ? null : chartDataset;
    }

    createChartDataset(data: AggregatedDataPoint[], measurementType: DataType, dateRange: TimeRange, stack: string, lastYearNumber?: number, label: string = AppContext.measurementName(measurementType), tint: number = 0, estimated?: boolean): MeasurementDataset {
        const chartColorRgba = DashboardContext.getMeasurementColor(stack, measurementType, tint);
        const groupedData = this.groupByTimeFrames(MeasurementsDataProvider.dataPointsToChartData(data), dateRange, this.info.resolution, AppContext.getAggregationMethod(measurementType));
        const order = AppContext.indexOfDataType(measurementType);
        return {
            measurementType: measurementType,
            dataset: {
                order: estimated ? order * 1000 : order,
                yAxisID: "y1",
                pointBorderColor: "rgb(0,0,0,0)",
                borderColor: chartColorRgba,
                backgroundColor: estimated ? "#FFFFFF" : chartColorRgba,
                borderWidth: estimated ? 1 : 0,
                label: estimated ? `${label} (${TranslateDirective.getTranslation("estimated", true)})` : label,
                data: groupedData.data,
                stack: stack,
                tooltip: {
                    formatter: this.chartUtils.getCustomTooltipFormatter(measurementType),
                    stackYear: lastYearNumber,
                    valuesInverted: AppContext.productionMeasurements.includes(measurementType),
                    labelOverride: "",
                    estimated: estimated
                }
            }
        };
    }

    getChartDataForMeasurement = (measurement: DataType, dateRange: TimeRange, isPrevious) =>
        this.getChartDataForMeasurements(measurement, dateRange, isPrevious
            ? this.dashboardData.previousData?.totals
            : this.dashboardData.currentData?.totals);

    getChartDataForMeasurements = (measurement: DataType, dateRange: TimeRange, measurements: MeasurementsResult[]) =>
        this.groupByTimeFrames(measurements ? measurements.flatMap(m =>
                MeasurementsDataProvider.dataPointsToChartData(m.measurements[measurement])) : [],
            dateRange, this.info.resolution, AppContext.getAggregationMethod(measurement));

    static appendAggregatedDataToSlots(data: AggregatedDataPoint[], slots: Slot[]): Slot[] {
        return this.appendDataToSlots(this.dataPointsToChartData(data), slots);
    }

    static appendDataToSlots(data: ChartSimpleData[], slots: Slot[]): Slot[] {
        const s = cloneDeep(slots);
        data.forEach(r => {
            const index = s.findIndex(s => lodash.inRange(r.x, s.startTime, s.endTime));
            if (index > -1) {
                s[index].values.push(r.y);
            }
        });
        return s;
    }

    static dataPointsToChartData(data: AggregatedDataPoint[]): ChartSimpleData[] {
        return data ? data.map(r => ({
            x: moment(r.timeRange.start).valueOf(),
            y: r.value
        })) : [];
    }

    isPortfolioProvider = (): boolean => this.sourceProvider instanceof OrganisationProvider;
}

export interface DashboardTime extends DateFieldRange {
    resolution?: TimeResolution;
}


export interface DashboardData {
    labels: string[];
    currentData?: ChartDataPerMeasurement;
    previousData?: ChartDataPerMeasurement;
}

export interface ChartDataPerMeasurement {
    totals?: MeasurementsResult[];
    byLocation?: MeasurementsResult[];
    measurements?: { [P in DataType]?: MeasurementDataset[] };
    estimatedMeasurements?: { [P in DataType]?: MeasurementDataset[] };
    totalsPerYear?: MeasurementsResult;
    byDayOfWeek?: DayOfWeekMeasurementsResult;
    completeness?: CompletenessInfo[];
    contractMeasurements?: ContractMeasurementResult;
}

export interface Slot {
    startTime: number;
    endTime: number;
    label: string;
    tableHeader: string;
    values: number[];
}

interface TimeSlots {
    [key: string]: {
        unit: DateTimeSlot;
        amount: number;
    }
}

export interface GroupedData {
    labels: string[];
    data: number[];
}

export interface ChartSimpleData {
    x: number;
    y: number;
}