import {DataType, TimeRange} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";
import {Injectable} from "@angular/core";
import {DecimalPipe} from "@angular/common";
import {DashboardContext} from "../dashboard/dashboard.context";
import {TooltipModel} from "chart.js/dist/types";
import moment from "moment";
import {localTimeFormat} from "../../common/utils";
import {DashboardTime} from "../../utils/measurements-data-provider";
import {ChartCompare} from "../dashboard/dashboard.types";

@Injectable()
export class ChartUtilsService {

    constructor(private decimalPipe: DecimalPipe) {

    }

    percentageFormatter = (value: number): string => `${this.transformDecimal(lodash.round(value * 100, 1))}%`;

    getCustomTooltipFormatter(measurementType: DataType, showBySquareMeter: boolean = false): (ctx: TooltipModel<any>, value: number) => string {
        switch(measurementType) {
            case DataType.sunHours: return (ctx: TooltipModel<any>, value: number) => this.percentageFormatter(value);
            default: return (ctx: TooltipModel<any>, value: number) => `${this.transformDecimal(value)} ${DashboardContext.getMeasurementUnit(measurementType)}${showBySquareMeter ? '/m²' : ''}`;
        }
    }

    getNumberTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
        return (ctx: TooltipModel<any>, value) => `${this.transformDecimal(value)}`;
    }

    transformDecimal = (value: number) => this.decimalPipe.transform(value);

    static createDiagonalPattern(color = 'black', direction: 'ltr' | 'rtl' = 'ltr'): CanvasPattern {
        let shape = document.createElement('canvas');
        shape.width = shape.height = 10;
        let c = shape.getContext('2d');
        c.strokeStyle = color;
        c.beginPath();
        if (direction === "ltr") {
            c.moveTo(2, 0);
            c.lineTo(10, 8);
            c.stroke();
            c.beginPath();
            c.moveTo(0, 8);
            c.lineTo(2, 10);
        } else {
            c.moveTo(8, 0);
            c.lineTo(0, 8);
            c.stroke();
            c.beginPath();
            c.moveTo(10, 8);
            c.lineTo(8, 10);
        }
        c.stroke();
        return c.createPattern(shape, 'repeat');
    }

    static getForYear(timeRange: DashboardTime, year: number): TimeRange {
        const time = {
            start: moment(timeRange.start),
            end: moment(timeRange.end)
        };
        const yearsAgo = time.start.year() - year;
        return {
            start: time.start.subtract(yearsAgo, "year").startOf(timeRange.resolution).format(localTimeFormat),
            end: time.end.subtract(yearsAgo, "year").startOf(timeRange.resolution).format(localTimeFormat)
        }
    }

    static getComparedYear(compare: ChartCompare, timeRange: TimeRange) {
        return compare.relative
            ? moment(timeRange.start).subtract(compare.comparedYear, 'year').year()
            : compare.comparedYear;
    }
}

export interface ChartModalOptions {
    fullScreen: boolean;
}