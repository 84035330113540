import {Component, ElementRef, inject, NgZone, OnDestroy, ViewChild} from '@angular/core';
import {AppContext} from "../../app-context";
import {Observable, of, takeUntil} from "rxjs";
import {
    entityToSourceInfo,
    SourceInfo,
    SourceSelectionChangeEvent
} from "../../utils/source-providers/sources-provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ChartUtilsService} from "../charts/chart-utils.service";
import {DashboardContext} from "../dashboard/dashboard.context";
import {Dashboard, DeleteDashboard, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, lodash, removeIf, uuid} from "../../common/utils";
import {RefdataUtils} from "../refdata/refdata-utils";
import {LocationProvider} from "../../utils/source-providers/location-provider";
import {DashboardType} from "../dashboard/dashboard.types";
import {MeterMeasurementsDataProvider} from "../../utils/meter-measurements-data-provider";
import {cloneDeep} from "lodash";
import {Entity} from "../../handlers/entity";
import {AppCommonUtils, publishEvent, sendQuery} from "../../common/app-common-utils";
import {Handler} from "../../common/handler";
import {View} from "../../common/view";
import {MeasurementsHandlerComponentData} from "../measurements-component/measurements-handler.component";
import {HandleQuery} from "../../common/handle-query";
import {MeasurementsDataProvider} from "../../utils/measurements-data-provider";


@Component({
    selector: 'app-location-dashboard',
    templateUrl: './location-dashboard.component.html',
    styleUrls: ['./location-dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
@Handler()
export class LocationDashboardComponent extends View implements OnDestroy {
    appContext = AppContext;
    refDataUtils = RefdataUtils;

    locationIds: string[];
    location: Entity;
    locations: Entity[];
    dataProvider: MeterMeasurementsDataProvider;
    dashboard: Dashboard = {info: {}};
    measurementsHandlerData: MeasurementsHandlerComponentData = {};

    private ngZone: NgZone = inject(NgZone);
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private chartUtils: ChartUtilsService = inject(ChartUtilsService);

    searchLocation = (term: string): Observable<Entity[]> => this.sendQuery("searchLocations", term);

    @ViewChild("sourceField", { read: ElementRef }) sourceField: ElementRef;

    constructor() {
        super();
        this.dataProvider = new MeterMeasurementsDataProvider(this.chartUtils);
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.updateAndNavigateToDashboard());
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : LocationDashboardComponent.defaultDashboard();
                this.dataProvider.ranges = DashboardContext.defaultRanges;
                this.dataProvider.info = this.dashboard.info;
                const savedLabel = this.dashboard.info?.predefinedTimeRange;
                if (savedLabel) {
                    const matchingRange = this.dataProvider.ranges.find(range => range.label === savedLabel);
                    if (matchingRange) {
                        this.dashboard.info.timeRange.start = matchingRange.start;
                        this.dashboard.info.timeRange.end = matchingRange.end;
                    }
                }
                this.dataProvider.sourceProvider = new LocationProvider();
                this.dataProvider.sourceProvider.selectedSources = this.dataProvider.info.sources;
                this.dataProvider.sourceProvider.selectionUpdated.pipe(takeUntil(this.dataProvider.notifier))
                    .subscribe(t => this.sourceSelectionUpdated(t));
                this.locationIds = this.dataProvider.info.sources.locationIds;
                this.measurementsHandlerData.chartOptions = this.dashboard.info.chartOptions;
                sendQuery("getLocationsAsEntities").subscribe((locations: Entity[]) => {
                    this.locations = locations;
                    if (this.dataProvider.info.sources.locationIds) {
                        this.location = locations.find(l => this.locationIds.includes(l.location.locationId));
                        if (this.location) {
                            setTimeout(() => this.updateLocation(this.location), 0);
                        }
                    }
                });
            });
        });
    }

    updateLocation(entity: Entity) {
        this.locationIds = [entity.location.locationId, ...entity.location.aliasIds];
        this.location = entity;
        this.dataProvider.sourceProvider.setData([entity.location]);
        this.sourceSelectionUpdated([this.dataProvider.sourceProvider.findById(entity.location.locationId).info]);
        publishEvent("sourceSelectionChange", <SourceSelectionChangeEvent> {
            selectedItems: [entityToSourceInfo(entity)],
            allSelectedItems: this.dataProvider.sourceProvider.getFlatSourceSelection()
        }, this.sourceField);
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.updateAndNavigateToDashboard();
            this.dataProvider.sourceChanged(sources);
        });
    }

    ngOnDestroy(): void {
        this.dataProvider.notifier.next(null);
        this.dataProvider.notifier.complete();
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.SaveDashboard", command, () =>
            AppCommonUtils.navigateToUrl("/location/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard))));
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppCommonUtils.navigateToUrl("/location/dashboard");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            const dashboard = cloneDeep(this.dashboard);
            this.dashboard.info.sources = this.dataProvider.sourceProvider.selectedSources;
            if (this.locationIds) {
                this.dataProvider.info.sources.locationIds = this.locationIds;
            }
            return lodash.isEqual(dashboard, this.dashboard) ? Promise.resolve()
                : this.router.navigateByUrl(`/location/dashboard/${DashboardContext.dashboardToBase64(this.dashboard)}`);
        });
    }

    static defaultDashboard = (): Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.type = DashboardType.Location;
        dashboard.info.chartOptions["Electricity"].splitOffPeak = false;
        dashboard.info.chartOptions["Electricity"].groupByEntityId = true;
        dashboard.info.chartOptions["Electricity"].showPower = true;
        dashboard.info.chartOptions["Electricity"].showGrossProduction = false;
        dashboard.info.chartOptions["Water"].groupByEntityId = true;
        return dashboard;
    }

    @HandleQuery("getDashboardDataProvider")
    getDashboardDataProvider(): Observable<MeasurementsDataProvider<any>> {
        return of(this.dataProvider);
    }
}