import {Component, OnInit} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {ChartDataPerMeasurement} from "../../../../utils/measurements-data-provider";
import {lodash} from "../../../../common/utils";
import {DashboardContext} from "../../dashboard.context";
import {AggregatedDataPoint, DataType, MeasurementsResult, MeasurementUnit} from "@flowmaps/flowmaps-typescriptmodels";
import {View} from "../../../../common/view";
import {Handler} from "../../../../common/handler";
import {DataQueryFilters} from "../../../measurements-component/measurements-handler.component";

@Component({
    selector: 'app-energy-summary-widget',
    templateUrl: './energy-summary-widget.component.html',
    styleUrls: ['./energy-summary-widget.component.scss']
})
@Handler()
export class EnergySummaryWidgetComponent extends View implements OnInit {
    appContext = AppContext;
    context = DashboardContext;

    values: SummedValues = {
        electricityConsumption: 0,
        electricityProduction: 0,
        electricityFeedIn: 0,
        gas: 0,
        heat: 0,
        water: 0
    }

    ngOnInit() {
        this.subscribeTo("getChartDataQueryFilters")
            .subscribe((f: DataQueryFilters) => this.sendQuery("getMeasurementsData", f.timeRange)
                .subscribe((d: ChartDataPerMeasurement) => this.values = this.mergeTotals(d.totals)));
    }

    private mergeTotals(totals: MeasurementsResult[]): SummedValues {
        return (totals || []).map(t => this.mergeSummedValues(this.getValues(t.measurements || {}), this.getValues(t.estimatedMeasurements || {})))
            .reduce((a, b) => this.mergeSummedValues(a, b));
    }

    private getValues(measurements: { [P in DataType]?: AggregatedDataPoint[] }): SummedValues {
        return {
            electricityConsumption: this.sumData((measurements.electricityConsumption || []).concat(measurements.electricityConsumptionOffPeak)),
            electricityFeedIn: this.sumData((measurements.electricityFeedIn || []).concat(measurements.electricityFeedInOffPeak)),
            electricityProduction: this.sumData((measurements.electricityGrossProduction || [])),
            gas: this.sumData(measurements.gasConsumption),
            heat: this.sumData(measurements.heatConsumption),
            water: this.sumData(measurements.waterConsumption)
        };
    }

    private mergeSummedValues = (values: SummedValues, other: SummedValues): SummedValues => {
        return {
            electricityConsumption: values.electricityConsumption + other.electricityConsumption,
            electricityFeedIn: values.electricityFeedIn + other.electricityFeedIn,
            electricityProduction: values.electricityProduction + other.electricityProduction,
            gas: values.gas + other.gas,
            heat: values.heat + other.heat,
            water: values.water + other.water
        }
    }

    sumData = (dataset: AggregatedDataPoint[]) => dataset ? lodash.sum(dataset.flatMap(d => d?.value)) || 0 : 0;

    electricityConsumptionUnit = () => this.context.getMeasurementUnit(DataType.electricityConsumption);
    electricityProductionUnit = () => this.context.getMeasurementUnit(DataType.electricityFeedIn);
    gasUnit = () => this.context.getMeasurementUnit(DataType.gasConsumption);
    waterUnit = () => this.context.getMeasurementUnit(DataType.waterConsumption);
    heatUnit = () => MeasurementUnit.GJ;
}

interface SummedValues {
    electricityConsumption: number;
    electricityFeedIn: number;
    electricityProduction: number;
    gas: number;
    heat: number;
    water: number;
}