import {Component, ElementRef, inject, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ChartDataset} from "chart.js";
import {
    AggregatedDataPoint,
    BuildingType,
    Connection,
    ConnectionType,
    DataType,
    MeasurementsResult,
    MeterType,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {
    ChartDataPerMeasurement,
    ChartSimpleData,
    DashboardTime,
    GroupedData,
    MeasurementsDataProvider
} from "../../utils/measurements-data-provider";
import {TooltipModel} from "chart.js/dist/types";
import {View} from "../../common/view";
import {Handler} from "../../common/handler";
import {ChartModalOptions, ChartUtilsService} from "./chart-utils.service";
import {AppContext, CompletenessInfo} from "../../app-context";
import {PaginationComponent} from "../../common/pagination/pagination.component";
import {ChartDataProvider} from "../../utils/chart-data-provider";
import {ChartCompare, ChartOptions, ChartOptionType} from "../dashboard/dashboard.types";
import {
    DataQueryFilters,
    MeasurementsHandlerOpenModalCommand
} from "../measurements-component/measurements-handler.component";
import {SourceInfo} from "../../utils/source-providers/sources-provider";
import {cloneObject, interpolate, lodash, nonNull} from "../../common/utils";
import {EntityType} from "../../handlers/entity";
import {Observable} from "rxjs";
import {sendCommandAndForget} from "../../common/app-common-utils";
import {DashboardContext} from "../dashboard/dashboard.context";
import {TranslateDirective} from "../../common/utils/translate.directive";
import moment from "moment/moment";
import {cloneDeep} from "lodash";
import {Rgba} from "../../common/rgba";

@Component({
    template: "",
})
@Handler()
export class BaseMeasurementChartComponent extends View implements OnInit {
    chartUtils = inject(ChartUtilsService);
    appContext = AppContext;
    @Input() showInReport: boolean;

    @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
    @ViewChild("pagination") pagination: PaginationComponent<any>;

    completenessInfo: ChartCompleteness;
    chartOptionType: ChartOptionType;
    mainConsumptionType: DataType;
    mainConnectionType: ConnectionType;
    connectionTypes: ConnectionType[] = [];

    chartDataProvider: ChartDataProvider = new ChartDataProvider();
    tableElement: ElementRef;
    dataProvider: MeasurementsDataProvider<any>;
    options: ChartOptions;
    modalOptions: ChartModalOptions = {
        fullScreen: false,
    }
    isPortfolio: boolean;
    filters: DataQueryFilters;
    timeRange: DashboardTime;
    comparedTimeRange: DashboardTime;
    selectedConnections: SourceInfo[] = [];
    feedInPowerTypes: DataType[] = [];
    contractedCapacityRequiredForPower: boolean;
    hasProductionMeter: boolean;
    groupByEntityId: boolean;

    _data: MeasurementDataset[];
    _rawData: ChartDataPerMeasurement;
    _rawPreviousData: ChartDataPerMeasurement;

    private uniqueId = lodash.uniqueId();
    getInputId = (id: string) => `${this.uniqueId}-${id}`;

    @Input()
    set data(d: ChartModalOptions) {
        this.modalOptions = d;
    }

    ngOnInit() {
        this.subscribeTo("getChartOptions", this.chartOptionType).subscribe((opts: ChartOptions) => {
            opts.showAllDays = opts.showAllDays === undefined ? true : opts.showAllDays;
            opts.selectedDataType = opts.selectedDataType || this.mainConsumptionType;
            opts.selectedDays = opts.selectedDays || [];
            this.options = opts;
        });
        this.subscribeTo("isPortfolio").subscribe(p => this.isPortfolio = p);
        this.subscribeTo("getChartDataQueryFilters")
            .subscribe((f: DataQueryFilters) => this.refreshAfterFilterChange(f));
        this.sendQuery("groupByEntityId").subscribe(val => this.groupByEntityId = val);
    }

    private refreshAfterFilterChange(f: DataQueryFilters) {
        this.filters = f;
        this.timeRange = f.timeRange;
        const allSelectedSources = f.allSelectedSources || [];
        this.selectedConnections = allSelectedSources.filter(s => this.connectionTypes.includes(s.connectionType) && s.type === EntityType.connection);
        this.hasProductionMeter = allSelectedSources.some(s => s.type === EntityType.meter && s.meterType === MeterType.GROSS_PRODUCTION);
        this.options.groupByEntityIdPossible = allSelectedSources.some(s => s.type === EntityType.meter
            && [MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION].includes(s.meterType)
            && this.connectionTypes.includes((<Connection>s.source.connection).info.connectionType as ConnectionType));
        this.sendQuery("getDataProvider").subscribe(d => {
            this.dataProvider = d;
            if (this.options?.compare.enabled) {
                this.refreshComparedData();
            }
        });
        this.getChartData(f.timeRange).subscribe((d: ChartDataPerMeasurement) => this.setData(d, f.timeRange));
    }

    private getChartData = (timeRange?: DashboardTime): Observable<ChartDataPerMeasurement> => {
        return this.sendQuery("getMeasurementsData", timeRange);
    }

    private getCompleteness = (timeRange?: DashboardTime): Observable<CompletenessInfo[]> => {
        return this.sendQuery("getCompleteness", timeRange);
    }

    openModal(type: typeof BaseMeasurementChartComponent) {
        this.sendCommandAndForget("openChartInModal", <MeasurementsHandlerOpenModalCommand>{
            component: type,
            chartOptions: {
                [this.chartOptionType]: this.options
            },
            chartModalData: this.modalOptions,
            container: this.container,
            modalClasses: "modal-dialog-centered modal-xl d-flex align-items-stretch"
        });
    }

    closeModal = () => sendCommandAndForget("closeModal");

    getSelectedEans = (): string[] => this.getSelectedConnections().map(c => c.source.connection.info.code);

    getSelectedConnections = (connectionType?: ConnectionType) => connectionType
        ? this.selectedConnections.filter(s => s.connectionType === connectionType) : this.selectedConnections;

    contractedCapacity(connectionType: ConnectionType) {
        const connections = this.getSelectedConnections(connectionType);
        if (this.powerAllowed(connectionType)) {
            if (this.isPortfolio) {
                return connections.length === 1 ? connections[0]?.source.connection.contractedCapacity : null;
            } else {
                return connections.find(c => c.source.connection.info.code === this.showPowerForEan(connectionType))?.source.connection.contractedCapacity;
            }
        }
        return null;
    }

    measurementUnit = () => DashboardContext.getMeasurementUnit(this.mainConsumptionType);

    get powerAxisTitle(): string {
        const hasWeatherSelected = this.options.selectedWeatherTypes?.length > 0;
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        const connectionType = this.getSelectedConnections()[0]?.connectionType;
        return !hasWeatherSelected && hasPowerSelected
            ? DashboardContext.getMeasurementUnit(this.powerDataTypeMapped()[connectionType]) : null;
    }

    get y2AxisTitle(): string {
        return this.options.selectedWeatherTypes?.length === 1
            ? DashboardContext.getMeasurementUnit(this.options.selectedWeatherTypes[0]) : null;
    }

    hasDataOfTypes = (dataType: DataType | DataType[]): boolean => {
        const dataTypes = lodash.isArray(dataType) ? dataType : [dataType];
        return [this._rawData?.measurements, this._rawData?.estimatedMeasurements,
            this._rawPreviousData?.measurements, this._rawPreviousData?.estimatedMeasurements].filter(nonNull)
            .some(d => Object.keys(d).some(k => dataTypes.includes(k as DataType)));
    }

    powerAllowed = (connectionType: ConnectionType): boolean => this.isPortfolio
        ? this.getSelectedConnections(connectionType).length === 1 : !!this.showPowerForEan(connectionType);

    hasPower = (connectionType?: ConnectionType): boolean => connectionType
        ? this.hasDataOfTypes(this.powerDataTypeMapped()[connectionType])
        : Object.entries(this.powerDataTypeMapped())
            .some(e => this.hasDataOfTypes(e[1]));

    getDataFromMeasurements = (data: { [P in DataType]?: MeasurementDataset[] }): MeasurementDataset[] => {
        return Object.values(data).flatMap(v => v).filter(v => v);
    }

    refreshData = (emit?: boolean) => {
        this._data = [];
        if (this._rawData) {
            this._data = this.getConsumptionAndProductionData(this.getDataFromMeasurements(this._rawData.measurements))
                .concat(this.getConsumptionAndProductionData(this.getDataFromMeasurements(this._rawData.estimatedMeasurements)));
            if (this.options.showCompleteness && this._rawData?.completeness) {
                this._data = this._data.concat(this.getCompletenessAxis(this._rawData.completeness));
            }
            this._data = this._data.concat(this.options.selectedWeatherTypes.map(dataType => this.getWeatherDataset(dataType, this.timeRange, false)));
        }
        if (this._rawPreviousData) {
            this._data = this._data.concat(this.getConsumptionAndProductionData(this.getDataFromMeasurements(this._rawPreviousData.measurements)))
                .concat(this.getConsumptionAndProductionData(this.getDataFromMeasurements(this._rawPreviousData.estimatedMeasurements)));
            if (this.options.showCompleteness && this._rawPreviousData?.completeness) {
                this._data = this._data.concat(this.getCompletenessAxis(this._rawPreviousData.completeness, true,
                    ChartUtilsService.getComparedYear(this.options.compare, this.timeRange)));
            }
            this._data = this._data.concat(this.options.selectedWeatherTypes.map(dataType => this.getWeatherDataset(dataType, this.comparedTimeRange, true)));
        }
        this._data = this._data.concat(this.addConnectionTypeSpecificData(this.timeRange));
        this.updateCompleteness();
        if (emit) {
            this.chartDataProvider.emit({
                datasets: this._data,
                labels: MeasurementsDataProvider.getSlots(this.timeRange, this.timeRange.resolution).map(s => s.label)
            });
        }
    }

    private updateCompleteness = () => {
        this.completenessInfo = this._rawData?.completeness ? {
            title: AppContext.completenessTitle(this._rawData.completeness, this.filters.allSelectedSources.filter(c => c.type === EntityType.connection), this.connectionTypes),
            color: AppContext.completenessColor(this._rawData.completeness, this.filters.allSelectedSources.filter(c => c.type === EntityType.connection), this.connectionTypes),
            completeness: AppContext.completeness(this._rawData.completeness, this.filters.allSelectedSources.filter(c => c.type === EntityType.connection), this.connectionTypes)
        } : null;
    }

    private addConnectionTypeSpecificData(timeRange: DashboardTime): MeasurementDataset[] {
        let datasets: MeasurementDataset[] = [];
        datasets = this.connectionTypes.flatMap(c => this.addPowerData(datasets, timeRange, c))
            .filter(a => a);
        return datasets;
    }

    private addPowerData(datasets: MeasurementDataset[], timeRange: DashboardTime, connectionType: ConnectionType) {
        const contractedCapacity = this.contractedCapacity(connectionType);
        const contractedRequired = this.contractedCapacityRequiredForPower;
        if ((contractedRequired && contractedCapacity) || !contractedRequired) {
            datasets = datasets.concat(this.addPowerToChart(timeRange, connectionType));
        }
        return datasets;
    }

    addPowerToChart(timeRange: DashboardTime, connectionType: ConnectionType): MeasurementDataset[] {
        const datasets: MeasurementDataset[] = [];
        const powerType = this.powerDataTypeMapped()[connectionType];
        if (this.options.showPower && this.powerAllowed(connectionType) && powerType) {
            if (this.hasPower(connectionType)) {
                datasets.push(this.getPowerDataset(powerType));
            }
            const contractedCapacity = this.contractedCapacity(connectionType);
            if (contractedCapacity) {
                const contractedCapacityColor = "#a2a2a2";
                datasets.push({
                    dataset: {
                        type: "line",
                        borderDash: [5],
                        order: AppContext.indexOfDataType(powerType),
                        yAxisID: "power",
                        spanGaps: false,
                        label: TranslateDirective.getTranslation("Contracted power", true),
                        borderColor: contractedCapacityColor,
                        backgroundColor: contractedCapacityColor,
                        pointBorderColor: contractedCapacityColor,
                        data: MeasurementsDataProvider
                            .getSlots(timeRange, timeRange.resolution)
                            .map(i => this.feedInPowerTypes.includes(powerType) ? -contractedCapacity : contractedCapacity),
                        stack: `contracted-capacity-${powerType}`,
                        tooltip: {
                            formatter: this.chartUtils.getCustomTooltipFormatter(powerType),
                            stack: AppContext.measurementName(powerType),
                            valuesInverted: this.feedInPowerTypes.includes(powerType)
                        }
                    }
                });
            }
        }
        return datasets;
    }

    private getPowerDataset(measurementType: DataType): MeasurementDataset {
        const color = DashboardContext.getMeasurementColor(DashboardContext.stacks.currentPeriod, measurementType);
        return {
            measurementType: measurementType,
            dataset: {
                type: "line",
                borderDash: [5],
                order: AppContext.indexOfDataType(measurementType),
                yAxisID: "power",
                spanGaps: false,
                borderColor: color,
                backgroundColor: color,
                pointBorderColor: color,
                label: AppContext.measurementName(measurementType),
                data: this.getPowerData(measurementType),
                stack: AppContext.measurementName(measurementType),
                tooltip: {
                    formatter: this.chartUtils.getCustomTooltipFormatter(measurementType),
                    valuesInverted: this.feedInPowerTypes.includes(measurementType)
                }
            }
        };
    }

    getPowerData(powerDataType: DataType) {
        let powerData: number[] = this.getChartDataForMeasurement(powerDataType, this.timeRange, false).data;
        if (this.feedInPowerTypes.includes(powerDataType)) {
            powerData = powerData.map(d => -d);
        }
        return powerData;
    }

    private setCompareData(data: ChartDataPerMeasurement, timeRange: DashboardTime, emitData: boolean = true) {
        this._rawPreviousData = null;
        if (data?.totals) {
            this.getCompleteness(timeRange).subscribe(c => {
                this._rawPreviousData = {
                    ...data,
                    measurements: this.groupDataByMeasurement(data.totals, timeRange, DashboardContext.stacks.lastYear, ChartUtilsService.getComparedYear(this.options.compare, this.timeRange), false, (m) => m.measurements),
                    estimatedMeasurements: this.groupDataByMeasurement(data.totals, timeRange, DashboardContext.stacks.lastYear, ChartUtilsService.getComparedYear(this.options.compare, this.timeRange), true, (m) => m.estimatedMeasurements),
                    completeness: c
                };
                this.refreshData(emitData);
            });
        } else {
            this.refreshData(emitData);
        }
    }

    private setData(data: ChartDataPerMeasurement, timeRange: DashboardTime, emitData: boolean = true) {
        this._rawData = null;
        if (data.totals) {
            this.getCompleteness(timeRange).subscribe(c => {
                this._rawData = {
                    ...data,
                    measurements: this.groupDataByMeasurement(data.totals, timeRange, DashboardContext.stacks.currentPeriod, null, false, (m) => m.measurements),
                    estimatedMeasurements: this.groupDataByMeasurement(data.totals, timeRange, DashboardContext.stacks.currentPeriod, null, true, (m) => m.estimatedMeasurements),
                    completeness: c
                };
                this.refreshData(emitData);
            });
        } else {
            this.refreshData(emitData);
        }
    }

    showFeedIn = (): boolean => this.options.showFeedIn || this.options.showFeedIn === undefined;
    showConsumption = (): boolean => this.options.showConsumption || this.options.showConsumption === undefined;
    isDataTypeSelected = (type: DataType): boolean => this.options.selectedWeatherTypes.includes(type);

    productionDataTypes = (): DataType[] => [];

    compareFormatter = (c: ChartCompare) => c.relative
        ? (c.comparedYear === 1
            ? TranslateDirective.getTranslation("Last year", true)
            : `${c.comparedYear} ${TranslateDirective.getTranslation("years ago", true)}`)
        : `${c.comparedYear}`;

    compareEquals = (c: ChartCompare, o: ChartCompare) => c && o && c.relative === o.relative && c.comparedYear === o.comparedYear;
    getWeatherInputId = (weatherType: DataType) => this.getInputId('checkbox-' + weatherType + '-' + this.chartOptionType);

    compareYearOptions: ChartCompare[] = [{
        comparedYear: 1,
        enabled: true,
        relative: true
    }].concat(AppContext.getYearsFrom(moment().year() - DashboardContext.maximumYears).map(y => ({
        comparedYear: y,
        enabled: true,
        relative: false
    })).reverse());

    toggleWeather(type: DataType) {
        const options = this.options;
        if (options.selectedWeatherTypes.includes(type)) {
            options.selectedWeatherTypes = options.selectedWeatherTypes.filter(t => t !== type);
        } else {
            options.selectedWeatherTypes.push(type);
        }
        this.refreshData(true);
    }

    compareChange(compare: boolean) {
        this.options.compare.enabled = compare;
        if (!this.options.compare.comparedYear) {
            this.options.compare.comparedYear = 1;
            this.options.compare.relative = true;
        }
        this.refreshComparedData();
    }

    comparedYearChange(compareInfo: ChartCompare) {
        this.options.compare = compareInfo
        this.options.compare.enabled = true;
        this.refreshComparedData();
    }

    private refreshComparedData = () => {
        if (this.options.compare.enabled) {
            this.comparedTimeRange = ChartUtilsService.getForYear(this.timeRange, ChartUtilsService.getComparedYear(this.options.compare, this.timeRange));
            this.comparedTimeRange.resolution = AppContext.resolutionForTimeRange(this.comparedTimeRange);
            this.getChartData(this.comparedTimeRange).subscribe(d => this.setCompareData(d, this.comparedTimeRange, true));
        } else {
            this.setCompareData(null, null,true);
        }
    }

    groupByEntityIdChange(groupByEntityId: boolean) {
        this.options.groupByEntityId = groupByEntityId;
        this.options.splitOffPeak = false;
        this.refreshData(true);
    }

    showGrossProductionChange(showGrossProduction: boolean) {
        this.options.showGrossProduction = showGrossProduction;
        this.refreshData(true);
    }

    showCompletenessChange(showCompleteness: boolean) {
        this.options.showCompleteness = showCompleteness;
        this.refreshData(true);
    }

    showPowerChange(showPower: boolean) {
        this.options.showPower = showPower;
        this.options.showPowerForEan = null;
        this.refreshData(true);
    }

    showPowerForEanChange(ean: string) {
        this.options.showPower = true;
        this.options.showPowerForEan = ean;
        this.refreshData(true);
    }

    showPowerForEan(connectionType: ConnectionType): string {
        if (this.isPortfolio) {
            return null;
        }
        const connections = this.getSelectedConnections(connectionType);
        if (connections.length === 1) {
            return this.options.showPower ? connections[0].source.connection.info.code : null
        }
        return this.options.showPowerForEan;
    }

    groupByEntityIdEnabled = (): boolean => this.options.groupByEntityId && this.options.groupByEntityIdPossible && this.groupByEntityId;

    toggleShowAsTable() {
        this.options.showAsTable = !this.options.showAsTable;
        setTimeout(() => this.chartDataProvider.emit({
            datasets: this._data,
            labels: MeasurementsDataProvider.getSlots(this.timeRange, this.timeRange.resolution).map(s => s.label)
        }), 0);
    }

    measurementTypes = (): DataType[] => [];

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementTypesMapped = (): {[key: string]: DataType[]} => ({});

    productionDataTypesMapped = (): {[key: string]: DataType[]} => ({})

    powerDataTypeMapped = (): {[key: string]: DataType} => {
        return {
            [ConnectionType.Electricity]: DataType.electricityPower,
            [ConnectionType.Gas]: DataType.gasPower,
            [ConnectionType.Heat]: DataType.heatPower,
            [ConnectionType.Water]: DataType.waterPower,
            [ConnectionType.Cooling]: DataType.coolingPower
        };
    }

    private getConsumptionAndProductionData(rawData: MeasurementDataset[]): MeasurementDataset[] {
        return this.groupByEntityIdEnabled()
            ? Object.entries(lodash.groupBy(rawData, r => r.entityId))
                .flatMap(s => this.createDatasets(s[1], rawData))
            : this.createDatasets(rawData, rawData);
    }

    private createDatasets(dataset: MeasurementDataset[], rawData: MeasurementDataset[]) {
        const consumptionData = this.processConsumptionData(this.getDataOfMeasurements(dataset, this.measurementTypes()), rawData);
        const prodData = this.processProductionData(this.getDataOfMeasurements(dataset, this.productionDataTypes()));
        const aggregatedProdData = this.getAggregatedProductionData(prodData, "y1");
        return this.applyProductionData(consumptionData, prodData)
            .concat(prodData)
            .concat(this.applyProductionData(this.getAggregatedConsumptionData(consumptionData, "y1"), aggregatedProdData))
            .concat(aggregatedProdData);
    }

    getDataOfMeasurements = (rawData: MeasurementDataset[], measurementTypes: DataType[]): MeasurementDataset[] =>
        rawData.filter(d => measurementTypes.includes(d.measurementType))
            .map(d => {
                const measurementSet = cloneDeep(d);
                if (measurementSet.measurementType === DataType.heatConsumption) {
                    measurementSet.dataset.data = measurementSet.dataset.data.map(v => (v as number) * DashboardContext.gjToM3Rate);
                }
                return measurementSet;
            });

    private processConsumptionData(consumptionData: MeasurementDataset[], allData: MeasurementDataset[]) {
        const link = this.measurementIntermediateLink();
        if (!this.groupByEntityIdEnabled() || !consumptionData.some(c => link.has(c.measurementType))) {
            return consumptionData;
        }
        const consumptionDataAggregated = this.getAggregatedConsumptionData(consumptionData, "y1");
        let data: MeasurementDataset[] = [];
        link.forEach((value, key) => {
            const intermediateConsumptions = allData
                .filter(m => value.includes(m.measurementType));
            let mainConsumptionSet = consumptionDataAggregated.find(c => c.measurementType === key);
            if (mainConsumptionSet) {
                mainConsumptionSet = cloneDeep(mainConsumptionSet);
                mainConsumptionSet.dataset.order = 10000;
                mainConsumptionSet.dataset.tooltip.data = cloneDeep(mainConsumptionSet.dataset.data as number[]);
                this.applyDiagonalPattern(mainConsumptionSet, mainConsumptionSet.dataset.backgroundColor as string, 'rtl');
                intermediateConsumptions.forEach(d => {
                    d.dataset.data.forEach((d, i) => {
                        mainConsumptionSet.dataset.data[i] = Math.max((mainConsumptionSet.dataset.data[i] as number) - (d as number), 0);
                    });
                });
                data = data.concat(mainConsumptionSet);
            }
        });
        return data;
    }

    private applyDiagonalPattern(d: MeasurementDataset, color: string = d.dataset.backgroundColor as string, direction: 'ltr' | 'rtl' = 'ltr') {
        d.dataset.backgroundColor = ChartUtilsService.createDiagonalPattern(color, direction);
        d.dataset.borderColor = color;
        d.dataset.borderWidth = 1;
    }

    private processProductionData(productionData: MeasurementDataset[]) {
        return productionData.map(p => {
            const d = cloneDeep(p);
            if (this.options.nettedConsumption) {
                this.applyDiagonalPattern(d, "#adadad");
                d.dataset.tooltip.valuesInverted = false;
            } else {
                d.dataset.data = d.dataset.data.map(r => -r);
            }
            return d;
        });
    }

    private applyProductionData(dataset: MeasurementDataset[], productionDataset: MeasurementDataset[]): MeasurementDataset[] {
        if (!this.options.nettedConsumption) {
            return dataset;
        }
        return cloneDeep(dataset).map(d => {
            const productionType = this.consumptionProductionLink().get(d.measurementType);
            if (productionType) {
                const prodDataset = productionDataset.find(s => s.measurementType === productionType);
                if (prodDataset) {
                    d.dataset.data = d.dataset.data.map((d, i) => (d as number) - (prodDataset.dataset.data[i] as number));
                    d.dataset.label = d.measurementType === DataType.electricityConsumption ? "Net consumption" : "Net consumption off-peak";
                }
            }
            return d;
        })
    }

    private getAggregatedConsumptionData(dataset: MeasurementDataset[], axis: string) {
        const mappedData = this.measurementTypesMapped();
        return Object.entries(mappedData).flatMap(e => {
            return this.getAggregatedData(dataset.filter(d => e[1].includes(d.measurementType)), axis, e[0] as DataType);
        });
    }

    private getAggregatedProductionData(dataset: MeasurementDataset[], axis: string) {
        const mappedData = this.productionDataTypesMapped();
        return Object.entries(mappedData).flatMap(e => {
            return this.getAggregatedData(dataset.filter(d => e[1].includes(d.measurementType)), axis, e[0] as DataType);
        });
    }

    private getAggregatedData(dataset: MeasurementDataset[], axis: string, m: DataType): MeasurementDataset[] {
        const axisData = dataset.filter(d => d && d.dataset["yAxisID"] === axis);
        if (axisData.length && axis === "y1" && this.getYAxis() !== axis) {
            const aggregatedData = cloneObject(axisData.find(a => a.measurementType === m) || axisData[0]);
            aggregatedData.dataset["yAxisID"] = this.getYAxis();
            aggregatedData.dataset.data = aggregatedData.dataset.data.map((r, i) =>
                lodash.sum(axisData.flatMap(r => r.dataset.data[i])));
            return [aggregatedData];
        }
        return [];
    }

    private getYAxis() {
        return this.options.splitOffPeak ? "y1" : "y1Aggregated";
    }

    private getCompletenessAxis = (completeness: CompletenessInfo[], isPrevious: boolean = false, comparedYear: number = null): MeasurementDataset => {
        let color = Rgba.fromString("rgba(163, 98, 201, 100)");
        if (isPrevious) {
            color = color.tint(0.25).greyScale();
        }
        const allConnectionsSelected = this.getSelectedConnections()
            .map(s => s.source.connection)
            .filter(s => this.connectionTypes.includes(s.info.connectionType as ConnectionType));
        const selection = allConnectionsSelected.map(s => s.connectionId);
        const completenessRecords = completeness
            .filter(c => selection.includes(c.connectionId))
            .filter(c => this.connectionTypes.includes(c.connectionType))
            .flatMap(c => c.meterCompleteness.map(c => c.meterView)
                .filter(m => m));
        const timeRange = isPrevious ? this.comparedTimeRange : this.timeRange;
        return {
            dataset: {
                type: "line",
                borderDash: [5],
                order: 10000,
                yAxisID: "completeness",
                spanGaps: false,
                label: TranslateDirective.getTranslation("Completeness", true),
                borderColor: color.toString(),
                backgroundColor: color.toString(),
                pointBorderColor: color.toString(),
                data: MeasurementsDataProvider.getSlots(timeRange, timeRange.resolution)
                    .map(i => {
                        return lodash.round(AppContext.computeCompleteness(completenessRecords, {
                            start: moment(i.startTime).toISOString(),
                            end: moment(i.endTime).toISOString()
                        }, allConnectionsSelected.filter(c => !c.meters.length)), 3);
                    }),
                stack: isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod,
                tooltip: {
                    stack: isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod,
                    stackYear: comparedYear,
                    formatter: (ctx: TooltipModel<any>, value: number) => this.chartUtils.percentageFormatter(value),
                    higherValueIsBetter: true
                }
            }
        };
    }

    protected groupDataByMeasurement(data: MeasurementsResult[], dateRange: TimeRange, stack: string, comparedYear: number, estimated: boolean, source: (m: MeasurementsResult) => { [P in DataType]?: AggregatedDataPoint[] }): { [P in DataType]?: MeasurementDataset[] } {
        const measurements: { [P in DataType]?: MeasurementDataset[] } = {};
        data.forEach((m, i) => {
            Object.entries(source(m)).forEach((e) => {
                const measurementType = e[0] as DataType;
                if (!measurements[measurementType]) {
                    measurements[measurementType] = [];
                }
                let dataset: MeasurementDataset;

                if (this.groupByEntityId) {
                    const entities = this.entitiesWithDataType(measurementType, data);
                    const color = entities.length > 1 ?
                        interpolate(entities.indexOf(m.entityId) + 1, 0, entities.length, -0.5, 0.5) : 0;
                    dataset = this.createChartDatasetForEntity(e[1], measurementType, dateRange, stack, m.entityId, color, comparedYear, estimated);
                } else {
                    dataset = this.createChartDataset(e[1], measurementType, dateRange, stack, comparedYear, AppContext.measurementName(measurementType), 0, estimated);
                }
                if (dataset) {
                    measurements[measurementType].push(dataset);
                }
            });
        });
        return measurements;
    }

    private entitiesWithDataType = (measurementType: DataType, data: MeasurementsResult[]): string[] =>
        data.filter(d => d.measurements[measurementType])
            .filter(d => d.measurements[measurementType].some(r => r.value !== 0))
            .map(d => d.entityId);

    private createChartDatasetForEntity(data: AggregatedDataPoint[], measurementType: DataType, dateRange: TimeRange, stack: string, entityId: string, color: number, lastYearNumber?: number, estimated?: boolean): MeasurementDataset {
        const chartDataset = this.createChartDataset(data, measurementType, dateRange, stack,
            lastYearNumber, `${AppContext.measurementName(measurementType)} - ${this.filters.allSelectedSources.find(s => s.id === entityId)?.name}`, color, estimated);
        chartDataset.entityId = entityId;
        chartDataset.dataset.order += color;
        return chartDataset.dataset.data.every(d => d === 0) ? null : chartDataset;
    }

    createChartDataset(data: AggregatedDataPoint[], measurementType: DataType, dateRange: DashboardTime, stack: string, lastYearNumber?: number, label: string = AppContext.measurementName(measurementType), tint: number = 0, estimated?: boolean): MeasurementDataset {
        const chartColorRgba = DashboardContext.getMeasurementColor(stack, measurementType, tint);
        const groupedData = this.groupByTimeFrames(MeasurementsDataProvider.dataPointsToChartData(data), dateRange, dateRange.resolution, AppContext.getAggregationMethod(measurementType));
        const order = AppContext.indexOfDataType(measurementType);
        return {
            measurementType: measurementType,
            dataset: {
                order: estimated ? order * 1000 : order,
                yAxisID: "y1",
                pointBorderColor: "rgb(0,0,0,0)",
                borderColor: chartColorRgba,
                backgroundColor: estimated ? "#FFFFFF" : chartColorRgba,
                borderWidth: estimated ? 1 : 0,
                label: estimated ? `${label} (${TranslateDirective.getTranslation("estimated", true)})` : label,
                data: groupedData.data,
                stack: stack,
                tooltip: {
                    formatter: this.chartUtils.getCustomTooltipFormatter(measurementType),
                    stackYear: lastYearNumber,
                    valuesInverted: AppContext.productionMeasurements.includes(measurementType),
                    labelOverride: "",
                    estimated: estimated
                }
            }
        };
    }

    private groupByTimeFrames(data: ChartSimpleData[], dateRange: TimeRange, selectedResolution: TimeResolution, aggregationMethod: (data: number[]) => number = lodash.sum): GroupedData {
        const slots = MeasurementsDataProvider.getSlots(dateRange, selectedResolution);
        return {
            labels: slots.map(s => s.label),
            data: MeasurementsDataProvider.appendDataToSlots(data, slots).map(e => aggregationMethod(e.values))
        };
    }

    getChartDataForMeasurement = (measurement: DataType, dateRange: DashboardTime, isPrevious) =>
        this.getChartDataForMeasurements(measurement, dateRange,
            isPrevious ? this._rawPreviousData?.totals : this._rawData?.totals);

    getChartDataForMeasurements = (measurement: DataType, dateRange: DashboardTime, measurements: MeasurementsResult[]) =>
        this.groupByTimeFrames(measurements ? measurements.flatMap(m =>
                MeasurementsDataProvider.dataPointsToChartData(m.measurements[measurement])) : [],
            dateRange, dateRange.resolution, AppContext.getAggregationMethod(measurement));

    private getWeatherDataset = (weatherType: DataType, timeRange: DashboardTime, isPrevious: boolean): MeasurementDataset => {
        const stack = isPrevious ? DashboardContext.stacks.lastYear : DashboardContext.stacks.currentPeriod;
        const color = DashboardContext.getMeasurementColor(stack, weatherType);
        return {
            measurementType: weatherType,
            dataset: {
                type: "line",
                order: AppContext.indexOfDataType(weatherType),
                yAxisID: weatherType,
                spanGaps: false,
                borderColor: color,
                backgroundColor: color,
                pointBorderColor: color,
                label: AppContext.measurementName(weatherType),
                data: this.getChartDataForMeasurement(weatherType, timeRange, isPrevious).data,
                stack: stack,
                tooltip: {
                    formatter: this.chartUtils.getCustomTooltipFormatter(weatherType),
                    stackYear: isPrevious ? ChartUtilsService.getComparedYear(this.options.compare, this.timeRange) : null
                }
            }
        };
    }
}

export interface ChartMeasurementData {
    dataProvider: MeasurementsDataProvider<any>;
    stacked?: boolean;
    fullScreen?: boolean;
}

export interface MeasurementData {
    labels?: string[];
    datasets: MeasurementDataset[];
}

export interface MeasurementDataset {
    measurementType?: DataType;
    entityId?: string;
    estimated?: boolean;
    dataset: ChartDatasetExtended;
}

export interface ChartDatasetExtended extends ChartDataset<'line'> {
    measurementType?: DataType;
    buildingType?: BuildingType;
    tooltipLabels?: string[];
    tooltip?: ChartDatasetTooltip;
}

interface ChartDatasetTooltip {
    stack?: string;
    valuesInverted?: boolean;
    higherValueIsBetter?: boolean;
    estimated?: boolean;
    stackYear?: number;
    formatter?: (ctx: TooltipModel<any>, value: number, index: number) => string;
    labelOverride?: string | string[];
    borderColors?: string[];
    data?: number[];
}

export interface ChartCompleteness {
    completeness: number;
    color: string;
    title: string;
}