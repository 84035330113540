import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import moment from "moment";
import {DatePickerComponent, DatePickerRange, PickerDateTimeRange} from "../date-picker/date-picker.component";
import {dispatchChangeEvent, uuid} from "../../utils";
import {AbstractValueAccessorComponent} from "../../component/value-accessor.component";
import {Moment} from "moment/moment";
import lodash from "lodash";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {DateFieldRange, MomentDateFieldRange} from "./date-field-range";
import {TimePickerInfo} from "../time-picker/time-picker.component";
import {DateRangeUtils} from "./date-range.utils";

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateRangeComponent), multi: true}
  ]
})
export class DateRangeComponent extends AbstractValueAccessorComponent<DateFieldRange> implements OnInit, AfterViewInit {
  private elementRef: ElementRef = inject(ElementRef);
  private changeDetectorRef = inject(ChangeDetectorRef);
  utils = DateRangeUtils;
  formattedDate: string;
  _date: DatePickerRange;
  time: string;
  startDating: Moment;
  _calendarStartDate: DatePickerRange;
  _minDate: Moment;

  @Input() id = uuid();
  @Input() dateOnly;
  @Input() defaultTime: string;
  @Input() disabled = false;
  @Input() required;
  @Input() yearSpan = 10;
  @Input() readonly;
  @Input() status: 'warning' | 'error';
  @Input() separator: string = " - ";
  @Input() includeTime: boolean;
  @Input() inputMode: 'input' | 'button' = 'input';
  @Input() ranges: MomentDateFieldRange[];
  @Input() show: boolean = true;
  @Input() showBackdrop: boolean;
  @Input() publishDefaultModelChange: boolean = true;

  @Input() localStorageKey: string;

  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  startTime: TimePickerInfo = {hour: 12, minute: 0, second: 0};
  endTime: TimePickerInfo = {hour: 12, minute: 0, second: 0};

  numberOfMonths: number = (window.innerWidth > 0 ? window.innerWidth : screen.width) > 500 ? 2 : 1;

  @Input() set minDate(date: Moment | string) {
    this._minDate = typeof date === "string" ? moment(date) : date;
    this.calendarStartDate = this._minDate;
  }

  @Input() set calendarStartDate(value: Moment) {
    this.startDating = value;
    if (this._date) {
      this.updateCalendarStartDate();
    }
  }

  set date(date: PickerDateTimeRange) {
    this._date = {
      start: moment(date.start),
      end: moment(date.end)
    };
    if (this.includeTime) {
      this.startTime = {
        hour: date.start.hour,
        minute: date.start.minute,
        second: date.start.second
      };
      this.endTime = {
        hour: date.end.hour,
        minute: date.end.minute,
        second: date.end.second
      };
    }
    if (this.includeTime && lodash.isEqual(this._date.start, this._date.end) && lodash.isEqual(this.startTime, this.endTime)) {
      this.startTime = {hour: 0, minute: 0, second: 0};
      this.endTime = {hour: 23, minute: 59, second: 59};
    }
    if (this.includeTime && lodash.isEqual(this._date.start, this._date.end)) {
      this._date.start = this._date.start.clone().startOf('day');
      this._date.end = this._date.end.clone().endOf('day');
    }
    this.updateCalendarStartDate();
  }

  @ViewChild("picker") pickerEl: ElementRef;
  @ViewChild(DatePickerComponent, {static: false}) pickerComponent: DatePickerComponent;
  showPicker: boolean;
  isShown: boolean;

  ngOnInit(): void {
    if (this.required === '') {
      this.required = true;
    }
    if (this.readonly === '') {
      this.readonly = true;
    }
  }

  ngAfterViewInit() {
    $(this.pickerEl.nativeElement).on("show.bs.dropdown", () => {
      this.showPicker = this.isShown = true;
      this.pickerComponent?.updateCalendar();
      this.changeDetectorRef.detectChanges();
      return true;
    });
    $(this.pickerEl.nativeElement).on("hide.bs.dropdown", () => {
      this.isShown = false;
      return true;
    });
    this.propagateModelChange(this.getDefaultDate());
  }

  private getDefaultDate(): DatePickerRange {
    let value: DatePickerRange;
    if (this.localStorageKey) {
      const preference: LocalStorageSettings = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (preference) {
        value = {
          start: moment().startOf('day').subtract(preference.daysBefore, 'd'),
          end: moment().startOf('day').add(preference.daysAfter, 'd')
        };
      }
    }
    if (!value) {
      const today = moment().startOf('day');
      value = {
        start: today.clone().subtract(7, 'day'),
        end: today.clone().add(7, 'day')
      }
      if (this.includeTime && this.defaultTime) {
        const time = moment(this.defaultTime, "HH:mm");
        value.start = this.dateAndTimeToMoment(value.start, {
          hour: time.hour(),
          minute: time.minute(),
          second: time.second()
        });
        value.end = this.dateAndTimeToMoment(value.end, {
          hour: time.hour(),
          minute: time.minute(),
          second: time.second()
        });
      }
    }
    return value;
  }

  selectRange = (range: MomentDateFieldRange) => {
    this.propagateModelChange(range);
    this.closeCalendar();
  }

  openCalendar = () => {
    const dropdownButton = this.getDropdownButton();
    dropdownButton.dropdown("show");
    setTimeout(() => dropdownButton.dropdown("update"), 0);
  };

  closeCalendar = () => {
    this.getDropdownButton().dropdown("hide");
  }

  getDropdownButton = () => {
    return $(this.elementRef.nativeElement).find('[data-bs-toggle="dropdown"]:first');
  }

  updateCalendarStartDate = (): void => {
    this._calendarStartDate = {
      start: moment(this._date.start),
      end: moment(this._date.end)
    }
  };

  onTimeChanged(time: TimePickerInfo, forDate: Moment) {
    forDate.hours(time.hour);
    forDate.minutes(time.minute);
    forDate.seconds(time.second);
    this.propagateModelChange(this._date);
  }

  onDatePickerChanged = (d: DatePickerRange) => {
    this.propagateModelChange(d);
    this.closeCalendar();
  }

  private propagateModelChange = (value: DatePickerRange) => {
    this.date = {
      start: DatePickerComponent.getTimeInfoFromDate(value.start),
      end: DatePickerComponent.getTimeInfoFromDate(value.end)
    };
    this.reformat();
    if (this.localStorageKey && value) {
      const now = moment().startOf('day');
      let daysBefore = now.diff(moment(value.start), 'days')
      let daysAfter = moment(value.end).diff(now, 'days');
      localStorage.setItem(this.localStorageKey, JSON.stringify(<LocalStorageSettings>{
        daysBefore: daysBefore,
        daysAfter: daysAfter
      }));
    }
    setTimeout(() => {
      this.onModelChange();
      dispatchChangeEvent(this.elementRef.nativeElement);
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  onDateChange = () => {
    const dates = this.formattedDate.split(this.separator);
    let ranges: DatePickerRange;
    if (dates.length == 2) {
      const start = moment(dates[0], this.getDisplayFormat());
      const end = moment(dates[1], this.getDisplayFormat());
      ranges = {
        start: start.isValid() ? start : end.clone().startOf('day').subtract(7, 'days'),
        end: end.isValid() ? end : start.clone().startOf('day').add(14, 'days')
      }
    } else if (this.formattedDate) {
      ranges = {
        start: moment(this.formattedDate, this.getDisplayFormat()),
        end: moment(this.formattedDate, this.getDisplayFormat()).clone().startOf('day').add(14, 'day')
      }
    } else {
      ranges = this.getDefaultDate();
    }
    setTimeout(() => this.propagateModelChange(ranges), 0)
  };

  get value(): DateFieldRange {
    return this._date?.start && this._date?.end
        ? {
          start: this.dateToIsoString(this._date.start, this.startTime, moment.defaultFormat),
          end: this.dateToIsoString(this._date.end, this.endTime, moment.defaultFormat),
          label: DateRangeUtils.getRangeLabel(this._date, this.ranges)
        } : null;
  }

  writeValue(value: DateFieldRange): void {
    value = !value || !value.start || !value.end ? null : value;
    if (!this._date) {
      this._date = {
        start: null,
        end: null
      };
    }
    this._date = {
      start: value?.start ? moment(value.start) : null,
      end: value?.end ? moment(value.end) : null
    };
    const [start, end] = [this._date.start, this._date.end];
    let startMoment = moment(start);
    this._date.start = this.validateDate(startMoment);
    let endMoment = moment(end);
    this._date.end = this.validateDate(endMoment);
    if (!this._date.start.isValid() || !this._date.end.isValid()) {
      this._date = this.getDefaultDate();
    }
    this.date = {
      start: DatePickerComponent.getTimeInfoFromDate(this._date.start),
      end: DatePickerComponent.getTimeInfoFromDate(this._date.end)
    };
    this.reformat();
  }

  private validateDate = (date: Moment): Moment => {
    if (!date || !date.isValid()) {
      return date;
    }
    if (this._minDate && date.isBefore(this._minDate)) {
      return this._minDate;
    }
    return date;
  };

  private reformat = () => {
    const displayFormat = this.getDisplayFormat();
    this.formattedDate = this._date.start
        ? this.dateToIsoString(this._date.start, this.startTime, displayFormat) + (this._date.end ? this.separator + this.dateToIsoString(this._date.end, this.endTime, displayFormat) : "")
        : "";
  };

  private getDisplayFormat(): string {
    return this.includeTime ? 'MMM DD, YYYY HH:mm:ss' : this.inputMode === "input" ? 'MMM DD, YYYY' : moment.defaultFormat;
  }

  private dateToIsoString(date: Moment, time: TimePickerInfo, displayFormat: string): string {
    return this.dateAndTimeToMoment(date, time)?.format(displayFormat);
  }

  private dateAndTimeToMoment(date: Moment, time: TimePickerInfo): Moment {
    if (!date) {
      return null;
    }
    if (!this.includeTime || !time) {
      time = {hour: 0, minute: 0, second: 0};
    }
    const m = moment({y: date.year(), M: date.month(), d: date.date(), h: time.hour, m: time.minute, s: time.second});
    return m.isValid() ? m : null;
  }
}

interface LocalStorageSettings {
  daysBefore: number;
  daysAfter: number;
}
