import {Component, ElementRef, inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardContext} from "./dashboard.context";
import {Observable, of, takeUntil} from "rxjs";
import {Dashboard, DeleteDashboard, Location, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, removeIf, uuid} from "../../common/utils";
import {AppContext} from "../../app-context";
import {ActivatedRoute, Router} from "@angular/router";
import {SourceInfo, TreeViewItem} from "../../utils/source-providers/sources-provider";
import {ChartUtilsService} from "../charts/chart-utils.service";
import {PortfolioMeasurementsDataProvider} from "../../utils/portfolio-measurements-data-provider";
import {Handler} from "../../common/handler";
import {View} from "../../common/view";
import {AppCommonUtils} from "../../common/app-common-utils";
import {EntityType} from "../../handlers/entity";
import {downloadBulkMonthData} from "../refdata/connections/connections-overview/bulk-data-download";
import {MeasurementsHandlerComponentData} from "../measurements-component/measurements-handler.component";
import {HandleQuery} from "../../common/handle-query";
import {MeasurementsDataProvider} from "../../utils/measurements-data-provider";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
@Handler()
export class DashboardComponent extends View implements OnInit, OnDestroy {
    appContext = AppContext;
    context = DashboardContext;

    private ngZone: NgZone = inject(NgZone);
    private router: Router = inject(Router)
    private route: ActivatedRoute = inject(ActivatedRoute);
    private chartUtils: ChartUtilsService = inject(ChartUtilsService);

    @ViewChild('leftMenuCollapse', {static: true}) filtersModal: ElementRef;
    @ViewChild('sourceListDropdown') sourceListDropdown: ElementRef;

    measurementsHandlerData: MeasurementsHandlerComponentData = {};

    addresses: Location[] = [];

    dataProvider: PortfolioMeasurementsDataProvider;
    dashboard: Dashboard = {info: {}};

    constructor() {
        super();
        this.dataProvider = new PortfolioMeasurementsDataProvider(this.chartUtils);
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : DashboardContext.defaultDashboard();
                this.dataProvider.info = this.dashboard.info;
                this.dataProvider.sourceProvider.selectedSources = this.dataProvider.info.sources;
                this.dataProvider.ranges = DashboardContext.defaultRanges;
                this.measurementsHandlerData.chartOptions = this.dashboard.info.chartOptions;
            });
        });
    }

    ngOnInit(): void {
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.updateAndNavigateToDashboard());
        this.dataProvider.sourceProvider.selectionUpdated.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.sourceSelectionUpdated(t));
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.updateAndNavigateToDashboard();
            this.dataProvider.sourceChanged(sources);
            this.addresses = this.getAddresses();
        });
    }

    ngOnDestroy(): void {
        this.dataProvider.notifier.next(null);
        this.dataProvider.notifier.complete();
    }

    private getAddresses() {
        const sourceSelection = this.dataProvider.sourceProvider.getTreeViewSelection();
        const items = sourceSelection.length > 0 ? sourceSelection : this.dataProvider.sourceProvider.data.flatMap(o=> o.subItems);
        return items.flatMap(this.getLocations);
    }

    private getLocations(item: TreeViewItem): Location[] {
        switch (item.info.type) {
            case EntityType.organisation:
                return item.info.source.organisation.locations;
            case EntityType.location:
                return [item.info.source.location];
            case EntityType.connection:
                return [item.parent.info.source.location];
            case EntityType.meter:
                return [item.parent.parent.info.source.location];
        }
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.SaveDashboard", command, () =>
            AppCommonUtils.navigateToUrl("/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard))));
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppCommonUtils.navigateToUrl("/");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            this.dashboard.info.sources = this.dataProvider.sourceProvider.selectedSources;
            return this.router.navigateByUrl("/dashboard/" + DashboardContext.dashboardToBase64(this.dashboard));
        });
    }

    downloadMonthlyData = () => downloadBulkMonthData(this.dataProvider.sourceProvider, this.dataProvider.info.timeRange);

    @HandleQuery("getDashboardDataProvider")
    getDashboardDataProvider(): Observable<MeasurementsDataProvider<any>> {
        return of(this.dataProvider);
    }
}
