import {Component, OnInit} from '@angular/core';
import {Handler} from "src/app/common/handler";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent} from "../base-measurement-chart";
import {ChartOptionType} from "../../dashboard/dashboard.types";

@Component({
  selector: 'app-water-chart',
  templateUrl: './water-chart.component.html',
  styleUrls: ['./water-chart.component.scss']
})
@Handler()
export class WaterChartComponent extends BaseMeasurementChartComponent implements OnInit {
  chartOptionType: ChartOptionType = ChartOptionType.Water;
  mainConsumptionType: DataType = DataType.waterConsumption;
  mainConnectionType: ConnectionType = ConnectionType.Water;
  connectionTypes: ConnectionType[] = [ConnectionType.Water];
  powerDataType: DataType = DataType.waterPower;
  contractedCapacityRequiredForPower: boolean = true;

  openModal() {
    super.openModal(WaterChartComponent);
  }

  measurementTypes = (): DataType[] => this.groupByEntityIdEnabled()
      ? [DataType.waterIntermediateConsumption] : [DataType.waterConsumption];

  measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

  consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

  measurementTypesMapped = (): {[key: string]: DataType[]} => ({});

  productionDataTypesMapped = (): {[key: string]: DataType[]} => {
    const measurementTypes = this.productionDataTypes();
    return {
      [measurementTypes[0]]: measurementTypes
    };
  }
}
