import {Component, OnInit} from '@angular/core';
import {Handler} from "src/app/common/handler";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent} from "../base-measurement-chart";
import {ChartOptionType} from "../../dashboard/dashboard.types";

@Component({
  selector: 'app-gas-chart',
  templateUrl: './gas-chart.component.html',
  styleUrls: ['./gas-chart.component.scss']
})
@Handler()
export class GasChartComponent extends BaseMeasurementChartComponent implements OnInit {
  chartOptionType: ChartOptionType = ChartOptionType.Gas;
  mainConsumptionType: DataType = DataType.gasConsumption;
  mainConnectionType: ConnectionType = ConnectionType.Gas;
  connectionTypes: ConnectionType[] = [ConnectionType.Gas, ConnectionType.Heat];
  contractedCapacityRequiredForPower: boolean = true;

  openModal() {
    super.openModal(GasChartComponent);
  }

  measurementTypes = (): DataType[] => {
    const consumptions = [DataType.gasConsumption, DataType.heatConsumption];
    if (this.options.showUncorrectedConsumption) {
      consumptions.push(DataType.gasConsumptionUncorrected);
    }
    return consumptions;
  };

  measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

  consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

  measurementTypesMapped = (): {[key: string]: DataType[]} => ({});

  productionDataTypesMapped = (): {[key: string]: DataType[]} => {
    const measurementTypes = this.productionDataTypes();
    return {
      [measurementTypes[0]]: measurementTypes
    };
  }

  showUncorrectedConsumptionChange(showUncorrectedConsumption: boolean) {
    this.options.showUncorrectedConsumption = showUncorrectedConsumption;
    this.refreshData(true);
  }
}
