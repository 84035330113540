import {AfterViewInit, Component, inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {Observable} from "rxjs";
import {sendFacetsQuery, subscribeTo} from "../../../common/app-common-utils";
import {map} from "rxjs/operators";
import {filterByTermArray, removeItem} from "../../../common/utils";
import {FacetUtils} from "../../../common/facets/facet-utils";
import {HandleQuery} from "../../../common/handle-query";
import {HandleCommand} from "../../../common/handle-command";
import {
  SlackAlertChannelDetailsComponent,
  SlackAlertChannelDetailsComponentData
} from "../slack-alert-channel-details/slack-alert-channel-details.component";
import {
  Error,
  FacetFilter,
  FacetResult,
  GetErrorEntries,
  GetErrors,
  SlackAlertChannel
} from "@flowmaps/flowmaps-typescriptmodels";
import {DateFieldRange} from "../../../common/date/date-range/date-field-range";
import {IssueNavigatorComponent, IssueNavigatorComponentData} from "../issue-navigator/issue-navigator.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AnimationTypes} from "../../../common/modal/modal";

@Component({
  selector: 'app-issues-overview',
  templateUrl: './issues-overview.component.html',
  styleUrls: ['./issues-overview.component.scss']
})
@Handler()
export class IssuesOverviewComponent extends View implements OnInit, AfterViewInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  facetUtils = FacetUtils;
  term: string;
  query: Observable<Error[]>;
  data: Error[] = [];
  slackChannels: SlackAlertChannel[] = [];
  dateRange: DateFieldRange;
  selecting: boolean;
  selectedItems: Error[] = [];

  facets: Observable<FacetResult>;
  facetFilters: FacetFilter[];
  defaultFacetFilters: FacetFilter[] = [{
    facetName: "status",
    values: ["OPEN"]
  }];
  hiddenFacets: string[] = ["errorId"];
  @ViewChild("filtersInModal") filtersTemplate: TemplateRef<any>;

  ngOnInit() {
    const issueId = this.route.snapshot.queryParamMap.get("issueId") || this.route.snapshot.queryParamMap.get("errorId");
    if (issueId) {
      this.openIssueDetails(issueId);
    }
  }

  ngAfterViewInit(): void {
    this.executeQuery();
    this.updateAlertChannels();
  }

  executeQuery() {
    const query: GetErrors = {facetFilters: this.facetFilters, timeRange: this.dateRange};
    this.query = this.subscribeTo("getErrors", query)
      .pipe(map((o: Error[]) => o.filter(filterByTermArray([this.term]))));
    this.facets = sendFacetsQuery("com.flowmaps.api.monitoring.errors.GetErrorsNew", query, {caching: false})
        .pipe(map(f => FacetUtils.addZeroFacetResults(f, "status", "OPEN", "RESOLVED", "MUTED")));
  }

  updateAlertChannels() {
    this.subscribeTo("findAlertChannels").subscribe(channels => this.slackChannels = channels);
  }

  trackByForRecord(index: number, record: Error) {
    return record.errorId;
  }

  openFiltersInModal = () => this.openModal(this.filtersTemplate, null);

  openSlackModal(record: SlackAlertChannel): void {
    this.openModal(SlackAlertChannelDetailsComponent, <SlackAlertChannelDetailsComponentData>{
      alertChannelId: record.alertChannelId,
      info: record.info
    });
  }

  addNewSlackChannel(): void {
    const newChannel: SlackAlertChannel = {
      info: {
        name: '',
        url: '',
      }
    };
    this.openSlackModal(newChannel);
  }

  trackByChannel = (index: number, channel: SlackAlertChannel) => channel.alertChannelId;

  calculateTotals = (): number => this.data.reduce((sum, error) => sum + error.count, 0);

  allItemsSelected = (): boolean => this.selectedItems.length === this.data.length;

  toggleSelectAll = (): void => {
    const allSelected = this.allItemsSelected();
    this.data.forEach(d => this.selectionChange({
      entity: d,
      selected: !allSelected
    }));
  };

  toggleSelection = () => {
    this.selecting = !this.selecting;
    if (!this.selecting) {
      this.selectedItems.forEach(c => delete c['selected']);
      this.selectedItems = [];
    }
  }

  @HandleCommand("selectionChange")
  selectionChange(record: { entity: Error, selected: boolean }) {
    if (record.selected) {
      if (!this.selectedItems.includes(record.entity)) {
        this.selectedItems.push(record.entity);
      }
    } else {
      this.selectedItems = removeItem(this.selectedItems, record.entity);
    }
    record.entity['selected'] = record.selected;
  }

  @HandleCommand("openIssueDetails")
  openIssueDetails(issueId: string) {
    this.openModal(IssueNavigatorComponent, <IssueNavigatorComponentData>{
      issueId: issueId
    }, {
      cssClass: "modal-dialog-centered  modal-xl",
      style: "height: 90vh; min-height: 90vh; width: 90vw; min-width: 90vw;",
      animation: AnimationTypes.zoomIn,
    }, () => {
      this.router.navigate(['/issues']);
      new Location().replace('/issues');
    })
  }

  @HandleCommand("reloadIssues", {global: true})
  reloadIssues() {
    this.executeQuery();
  }

  @HandleQuery("getIssueEntries")
  getIssueEntries(query: GetErrorEntries) {
    return subscribeTo('com.flowmaps.api.monitoring.errors.GetErrorEntriesNew', query, {caching: false});
  }
}
