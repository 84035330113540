import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {NgChartsModule} from 'ng2-charts';
import {FormsModule} from "@angular/forms";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {MainMenuComponent} from './views/main-menu/main-menu.component';
import {SignInComponent} from './views/signed-out/sign-in/sign-in.component';
import {EditProfileComponent} from './views/edit-profile/edit-profile.component';
import {ForgotPasswordComponent} from './views/signed-out/forgot-password/forgot-password.component';
import {SignedOutComponent} from './views/signed-out/signed-out.component';
import {HomeComponent} from './views/home/home.component';
import {EditProfileSecurityComponent} from './views/edit-profile/edit-profile-security/edit-profile-security.component';
import {EditProfileGeneralComponent} from './views/edit-profile/edit-profile-general/edit-profile-general.component';
import {SignUpComponent} from './views/signed-out/sign-up/sign-up.component';
import {TimestampPipe} from "./common/timestamp.pipe";
import {RouteReuseStrategy} from "@angular/router";
import {RouteStrategyService} from "./utils/route-strategy.service";
import {AuthService} from "./utils/auth.service";
import {CommonModule, DecimalPipe, TitleCasePipe} from "@angular/common";
import {TypeaheadWindowComponent} from "./common/typeahead/typeahead-window.component";
import {PopoverComponent} from "./common/popover/popover.component";
import {HighlightComponent} from "./common/typeahead/highlight/highlight.component";
import {ConfirmUserComponent} from "./views/signed-out/confirm-user/confirm-user.component";
import {ResetPasswordComponent} from "./views/signed-out/reset-password/reset-password.component";
import {CommonsModule} from "./common/commons.module";
import {DndModule} from "ngx-drag-drop";
import {CommandGateway} from "./common/command-gateway";
import {QueryGateway} from "./common/query-gateway";
import {EventGateway} from "./common/event-gateway";
import {InjectorProvider} from "./common/app-common-utils";
import {HandlerRegistry} from "./common/handler-registry.service";
import {EncryptComponent} from "./views/admin/encrypt/encrypt.component";
import {
    OrganisationsOverviewComponent
} from "./views/refdata/organisations/organisations-overview/organisations-overview.component";
import {AppEntityHandler} from "./handlers/app-entity-handler.service";
import {
    OrganisationRoutingComponent
} from './views/refdata/organisations/organisation-routing/organisation-routing.component';
import {
    OrganisationDetailsComponent
} from './views/refdata/organisations/organisation-details/organisation-details.component';
import {OrganisationInfoComponent} from "./views/refdata/organisations/organisation-info/organisation-info.component";
import {
    AuthorisationsOverviewComponent
} from './views/refdata/authorisations/authorisations-overview/authorisations-overview.component';
import {
    OrganisationOverviewItemComponent
} from "./views/refdata/organisations/organisations-overview/organisation-overview-item/organisation-overview-item.component";
import {
    AuthorisationOverviewItemComponent
} from "./views/refdata/authorisations/authorisations-overview/authorisation-overview-item/authorisation-overview-item.component";
import {ConnectionImageComponent} from "./views/common/connection-image/connection-image.component";
import {AddressFormComponent} from "./views/common/address-form/address-form.component";
import {
    AuthorisationDetailsComponent
} from './views/refdata/authorisations/authorisation-details/authorisation-details.component';
import {SourcesSelectorComponent} from "./views/common/sources-selector/sources-selector.component";
import {MultiSelectComponent} from "./views/common/multi-select/multi-select.component";
import {DashboardSourcesListComponent} from "./views/common/dashboard-sources-list/dashboard-sources-list.component";
import {AddOrganisationComponent} from "./views/refdata/organisations/add-organisation/add-organisation.component";
import {LocationsOverviewComponent} from './views/refdata/locations/locations-overview/locations-overview.component';
import {
    LocationOverviewItemComponent
} from './views/refdata/locations/locations-overview/location-overview-item/location-overview-item.component';
import {LabelComponent} from "./views/common/label/label.component";
import {EnergyLabelComponent} from "./views/common/energy-label/energy-label.component";
import {LocationDetailsComponent} from "./views/refdata/locations/location-details/location-details.component";
import {
    ConnectionsOverviewComponent
} from './views/refdata/connections/connections-overview/connections-overview.component';
import {
    ConnectionsOverviewItemComponent
} from './views/refdata/connections/connections-overview/connections-overview-item/connections-overview-item.component';
import {ConnectionDetailsComponent} from './views/refdata/connections/connection-details/connection-details.component';
import {
    ServiceProviderSelectComponent
} from "./views/refdata/service-provider-select/service-provider-select.component";
import {RefDataStandaloneHandler} from "./handlers/refdata-standalone-handler";
import {
    ElectricityDetailsComponent
} from "./views/refdata/connections/connection-details/electricity-details/electricity-details.component";
import {GasDetailsComponent} from "./views/refdata/connections/connection-details/gas-details/gas-details.component";
import {
    ConnectionValueSelectComponent
} from "./views/refdata/connections/connection-value-select/connection-value-select.component";
import {
    UpsertSolarEdgeMeterComponent
} from "./views/refdata/meters/upsert-solar-edge-meter/upsert-solar-edge-meter.component";
import {
    LinkContractConnectionComponent
} from "./views/refdata/connections/link-contract-connection/link-contract-connection.component";
import {ContractRangeComponent} from "./views/refdata/contracts/contract-range/contract-range.component";
import {MeterViewsStandaloneHandler} from "./handlers/meter-views-standalone-handler";
import {ChartUtilsService} from "./views/charts/chart-utils.service";
import {
    ConnectionSelectionComponent
} from "./views/refdata/connections/connection-selection/connection-selection.component";
import {UsersOverviewComponent} from './views/refdata/users/users-overview/users-overview.component';
import {
    UsersOverviewItemComponent
} from './views/refdata/users/users-overview/users-overview-item/users-overview-item.component';
import {UserDetailsComponent} from './views/refdata/users/user-details/user-details.component';
import {AppQueryCacheHandler} from "./handlers/app-query-cache-handler";
import {TaxesOverviewComponent} from './views/refdata/taxes/taxes-overview/taxes-overview.component';
import {
    TaxesOverviewItemComponent
} from './views/refdata/taxes/taxes-overview/taxes-overview-item/taxes-overview-item.component';
import {AddTaxComponent} from './views/refdata/taxes/add-tax/add-tax.component';
import {TaxPeriodDetailsComponent} from './views/refdata/taxes/tax-period-details/tax-period-details.component';
import {ContractsOverviewComponent} from './views/refdata/contracts/contracts-overview/contracts-overview.component';
import {
    ContractOverviewItemComponent
} from './views/refdata/contracts/contracts-overview/contract-overview-item/contract-overview-item.component';
import {
    ContractDetailsModalComponent
} from './views/refdata/contracts/contract-details-modal/contract-details-modal.component';
import {ContractInfoComponent} from './views/refdata/contracts/contract-info/contract-info.component';
import {
    ContractPeriodDetailsComponent
} from './views/refdata/contracts/contract-period-details/contract-period-details.component';
import {
    SustainabilitySourceSelectComponent
} from "./views/refdata/sustainability-source-select/sustainability-source-select.component";
import {ContractRoutingComponent} from './views/refdata/contracts/contract-routing/contract-routing.component';
import {ContractDetailsComponent} from './views/refdata/contracts/contract-details/contract-details.component';
import {
    ContractPeriodsOverviewComponent
} from './views/refdata/contracts/contract-periods-overview/contract-periods-overview.component';
import {
    ContractPeriodOverviewItemComponent
} from './views/refdata/contracts/contract-periods-overview/contract-period-overview-item/contract-period-overview-item.component';
import {
    LinkedContractsOverviewComponent
} from './views/refdata/contracts/linked-contracts-overview/linked-contracts-overview.component';
import {
    LinkedContractOverviewItemComponent
} from './views/refdata/contracts/linked-contracts-overview/linked-contract-overview-item/linked-contract-overview-item.component';
import {
    LinkedContractConnectionOverviewItemComponent
} from './views/refdata/contracts/linked-contracts-overview/linked-contract-connection-overview-item/linked-contract-connection-overview-item.component';
import {
    LinkContractOrganisationComponent
} from './views/refdata/contracts/link-contract-organisation/link-contract-organisation.component';
import {
    LinkContractDetailsComponent
} from './views/refdata/contracts/link-contract-details/link-contract-details.component';
import {IssuesOverviewComponent} from "./views/issues/issues-overview/issues-overview.component";
import {
    IssueOverviewItemComponent
} from "./views/issues/issues-overview/issue-overview-item/issue-overview-item.component";
import {IssueNavigatorComponent} from "./views/issues/issue-navigator/issue-navigator.component";
import {IssueEntryComponent} from "./views/issues/issue-entry/issue-entry.component";
import {IssueActionsDropdownComponent} from "./views/issues/issue-actions-dropdown/issue-actions-dropdown.component";
import {
    SlackAlertChannelDetailsComponent
} from "./views/issues/slack-alert-channel-details/slack-alert-channel-details.component";
import {IssuesStandaloneHandler} from "./handlers/issues-standalone-handler";
import {IssueSelectionComponent} from "./views/issues/issue-selection/issue-selection.component";
import {AlertsOverviewComponent} from './views/alerts/alerts-overview/alerts-overview.component';
import {
    AlertOverviewItemComponent
} from './views/alerts/alerts-overview/alert-overview-item/alert-overview-item.component';
import {AddAlertComponent} from './views/alerts/add-alert/add-alert.component';
import {AlertSelectionComponent} from "./views/alerts/alert-selection/alert-selection.component";
import {DashboardComponent} from "./views/dashboard/dashboard.component";
import {DashboardMapsComponent} from "./views/dashboard/dashboard-maps/dashboard-maps.component";
import {
    EnergySummaryWidgetComponent
} from "./views/dashboard/widgets/energy-summary-widget/energy-summary-widget.component";
import {
    DataProviderDateRangeComponent
} from "./views/dashboard/data-provider-date-range/data-provider-date-range.component";
import {BarChartComponent} from "./views/charts/bar-chart/bar-chart.component";
import {DoughnutChartComponent} from "./views/charts/doughnut-chart/doughnut-chart.component";
import {DayOfWeekChart} from "./views/charts/weekdays-chart/day-of-week-chart.component";
import {ScatterChartComponent} from "./views/charts/scatter-chart/scatter-chart.component";
import {EnergyCompassChartComponent} from "./views/charts/energy-compass-chart/energy-compass-chart.component";
import {CostsChartComponent} from "./views/charts/costs-chart/costs-chart.component";
import {Co2ChartComponent} from "./views/charts/co2-chart/co2-chart.component";
import {ChartDownloadButton} from "./views/charts/chart-download-button/chart-download-button.component";
import {
    LocationPerformanceChartComponent
} from "./views/charts/entity-performance-chart/location-performance-chart.component";
import {ChartTableComponent} from "./views/charts/chart-table/chart-table.component";
import {
    MeterPerformanceChartComponent
} from "./views/charts/entity-performance-chart/meter-performance-chart.component";
import {DashboardChartTooltipComponent} from "./views/charts/dashboard-chart-tooltip/dashboard-chart-tooltip.component";
import {LocationDashboardComponent} from "./views/location-dashboard/location-dashboard.component";
import {LocationMetersComponent} from "./views/location-dashboard/location-meters/location-meters.component";
import {
    LocationMeterTypeInfoComponent
} from "./views/location-dashboard/location-meters/location-meter-type-info/location-meter-type-info.component";
import {
    LocationEnergyLabelInfoComponent
} from "./views/location-dashboard/location-energy-label-info/location-energy-label-info.component";
import {
    LocationEnergyCompassComponent
} from "./views/location-dashboard/location-energy-compass/location-energy-compass.component";
import {MeterImageComponent} from "./views/common/meter-image/meter-image.component";
import {MeterDetailsComponent} from "./views/refdata/meters/meter-details/meter-details.component";
import {
    EnergyCompassTableComponent
} from "./views/charts/energy-compass-chart/energy-compass-table/energy-compass-table.component";
import {ReportRunnerComponent} from "./views/reports/report-runner/report-runner.component";
import {ReportComponent} from "./views/reports/report/report.component";
import {ReportDetailsComponent} from "./views/reports/report-details/report-details.component";
import {ReportHtmlToPdfComponent} from "./views/reports/report-html-to-pdf/report-html-to-pdf.component";
import {
    ReportEntityPerformancePageComponent
} from "./views/reports/report-html-page/report-entity-performance-page/report-entity-performance-page.component";
import {ReportHeaderComponent} from "./views/reports/report-html-page/report-header/report-header.component";
import {
    ReportHtmlFrontPageComponent
} from "./views/reports/report-html-page/report-html-front-page/report-html-front-page.component";
import {ReportHtmlPageComponent} from "./views/reports/report-html-page/report-html-page.component";
import {
    ReportSelectionComponent
} from "./views/reports/report-html-page/report-html-front-page/report-selection/report-selection.component";
import {ApiKeysOverviewComponent} from './views/edit-profile/api-keys/api-keys-overview/api-keys-overview.component';
import {ApiKeyDetailsComponent} from './views/edit-profile/api-keys/api-key-details/api-key-details.component';
import {
    ApiKeysOverviewItemComponent
} from './views/edit-profile/api-keys/api-keys-overview-item/api-keys-overview-item.component';
import {ApiKeyResponseComponent} from './views/edit-profile/api-keys/api-key-response/api-key-response.component';
import {MeasurementsHandlerComponent} from './views/measurements-component/measurements-handler.component';
import {
    EntityPerformanceChartComponent
} from "./views/charts/entity-performance-chart/entity-performance-chart.component";
import {AppMeasurementsHandler} from "./handlers/app-measurements-handler";
import {
    DashboardSourcesListItemComponent
} from './views/common/dashboard-sources-list/dashboard-sources-list-item/dashboard-sources-list-item.component';
import {ElectricityChartComponent} from './views/charts/electricity-chart/electricity-chart.component';
import {GasChartComponent} from "./views/charts/gas-chart/gas-chart.component";
import {WaterChartComponent} from "./views/charts/water-chart/water-chart.component";
import {BaseMeasurementChartComponent} from "./views/charts/base-measurement-chart";

@NgModule({
    declarations: [
        AppComponent,
        MainMenuComponent,

        // Common
        MultiSelectComponent,
        AddressFormComponent,
        ConnectionImageComponent,
        SourcesSelectorComponent,
        DashboardSourcesListComponent,
        DashboardSourcesListItemComponent,
        LabelComponent,
        EnergyLabelComponent,
        MeterImageComponent,

        // Home
        HomeComponent,

        // Dashboard
        DashboardComponent,
        DashboardMapsComponent,
        EnergySummaryWidgetComponent,
        DataProviderDateRangeComponent,

        // Location dashboard
        LocationDashboardComponent,
        LocationMetersComponent,
        LocationMeterTypeInfoComponent,
        LocationEnergyLabelInfoComponent,
        LocationEnergyCompassComponent,

        // Admin
        EncryptComponent,

        // Sign in
        SignInComponent,
        ForgotPasswordComponent,
        SignedOutComponent,
        SignUpComponent,
        ConfirmUserComponent,
        ResetPasswordComponent,

        // User profile
        EditProfileComponent,
        EditProfileSecurityComponent,
        EditProfileGeneralComponent,

        // Chart
        BaseMeasurementChartComponent,
        BarChartComponent,
        DoughnutChartComponent,
        ElectricityChartComponent,
        GasChartComponent,
        WaterChartComponent,
        DayOfWeekChart,
        ScatterChartComponent,
        EnergyCompassChartComponent,
        EnergyCompassTableComponent,
        EntityPerformanceChartComponent,
        MeterPerformanceChartComponent,
        LocationPerformanceChartComponent,
        CostsChartComponent,
        Co2ChartComponent,
        ChartDownloadButton,
        ChartTableComponent,
        DashboardChartTooltipComponent,

        // Reports
        ReportComponent,
        ReportDetailsComponent,
        ReportHtmlToPdfComponent,
        ReportEntityPerformancePageComponent,
        ReportHeaderComponent,
        ReportHtmlFrontPageComponent,
        ReportHtmlPageComponent,
        ReportSelectionComponent,
        ReportRunnerComponent,

        // RefData
        ServiceProviderSelectComponent,
        SustainabilitySourceSelectComponent,

        // Organisation
        OrganisationsOverviewComponent,
        OrganisationOverviewItemComponent,
        AddOrganisationComponent,
        OrganisationRoutingComponent,
        OrganisationDetailsComponent,
        OrganisationInfoComponent,

        // Authorisations
        AuthorisationsOverviewComponent,
        AuthorisationOverviewItemComponent,
        AuthorisationDetailsComponent,

        // Locations
        LocationsOverviewComponent,
        LocationOverviewItemComponent,
        LocationDetailsComponent,

        // Connections
        ConnectionsOverviewComponent,
        ConnectionSelectionComponent,
        ConnectionsOverviewItemComponent,
        ConnectionDetailsComponent,
        ElectricityDetailsComponent,
        GasDetailsComponent,
        ConnectionValueSelectComponent,
        LinkContractConnectionComponent,

        // Meters
        MeterDetailsComponent,
        UpsertSolarEdgeMeterComponent,

        // Contracts
        ContractRangeComponent,

        // Users
        UsersOverviewComponent,
        UsersOverviewItemComponent,
        UserDetailsComponent,

        // Taxes
        TaxesOverviewComponent,
        TaxesOverviewItemComponent,
        AddTaxComponent,
        TaxPeriodDetailsComponent,

        // Contracts
        ContractsOverviewComponent,
        ContractOverviewItemComponent,
        ContractDetailsModalComponent,
        ContractInfoComponent,
        ContractPeriodDetailsComponent,
        ContractRoutingComponent,
        ContractDetailsComponent,
        ContractPeriodsOverviewComponent,
        ContractPeriodOverviewItemComponent,
        LinkedContractsOverviewComponent,
        LinkedContractOverviewItemComponent,
        LinkedContractConnectionOverviewItemComponent,
        LinkContractOrganisationComponent,
        LinkContractDetailsComponent,

        // Issues
        IssuesOverviewComponent,
        IssueOverviewItemComponent,
        IssueSelectionComponent,
        IssueNavigatorComponent,
        IssueEntryComponent,
        IssueActionsDropdownComponent,
        SlackAlertChannelDetailsComponent,

        // Alerts
        AlertsOverviewComponent,
        AlertOverviewItemComponent,
        AlertSelectionComponent,
        AddAlertComponent,
        ApiKeysOverviewComponent,
        ApiKeyDetailsComponent,
        ApiKeysOverviewItemComponent,
        ApiKeyResponseComponent,
        MeasurementsHandlerComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgChartsModule,
        LeafletModule,
        CommonModule,
        FormsModule,
        CommonsModule,
        TypeaheadWindowComponent,
        PopoverComponent,
        HighlightComponent,
        DndModule
    ],
    providers: [
        HandlerRegistry,
        CommandGateway,
        QueryGateway,
        EventGateway,
        TitleCasePipe,
        TimestampPipe,
        DecimalPipe,
        AuthService,
        ChartUtilsService,
        HttpClient,
        AppQueryCacheHandler,
        AppEntityHandler,
        RefDataStandaloneHandler,
        MeterViewsStandaloneHandler,
        IssuesStandaloneHandler,
        AppMeasurementsHandler,
        {provide: RouteReuseStrategy, useClass: RouteStrategyService},
        {provide: LOCALE_ID, useValue: 'nl-NL'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        InjectorProvider.injector = injector;
        InjectorProvider.injector.get(AuthService);
        InjectorProvider.injector.get(HttpClient);
        InjectorProvider.injector.get(AppQueryCacheHandler);
        InjectorProvider.injector.get(AppEntityHandler);
        InjectorProvider.injector.get(IssuesStandaloneHandler);
        InjectorProvider.injector.get(RefDataStandaloneHandler);
        InjectorProvider.injector.get(MeterViewsStandaloneHandler);
        InjectorProvider.injector.get(AppMeasurementsHandler);
    }
}