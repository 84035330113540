import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {SourcesProvider, TreeViewItem} from "../../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";

@Component({
  selector: 'app-dashboard-sources-list-item',
  templateUrl: './dashboard-sources-list-item.component.html',
  styleUrls: ['./dashboard-sources-list-item.component.css']
})
export class DashboardSourcesListItemComponent implements AfterViewInit {
  private changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private elementRef: ElementRef = inject(ElementRef);

  @Input() item: TreeViewItem;
  @Input() depth: number = 0;
  @Input() recordCls: string = "";
  @Input() filterTerm: string;
  @Input() dataProvider: OrganisationProvider;

  @Output() selectionChange: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

  ngAfterViewInit() {
    this.item.element = this.elementRef;
  }

  toggleExpand(source: TreeViewItem) {
    source.expanded = !source.expanded;
    if (!source.expanded) {
      collapseItems(source);
    }

    function collapseItems(parent: TreeViewItem) {
      if (parent.subItems) {
        parent.subItems.forEach(s => {
          s.expanded = false;
          collapseItems(s);
        })
      }
    }
    this.changeDetector.detectChanges();
  }

  toggleSource(source: TreeViewItem) {
    const selectedParent = this.dataProvider.getSelectedParent(source);
    if (selectedParent) {
      this.toggleSourceParent(source);
      this.dataProvider.selectSource(source, false);
      this.dataProvider.selectionUpdated.emit(this.dataProvider.getSelection());
      this.selectionChange.emit(source);
      return;
    }
    if (this.dataProvider.isSelected(source) && !selectedParent) {
      SourcesProvider.flatMapSubItems(source)
          .filter(s => s !== source)
          .forEach(s => this.dataProvider.selectSource(s, false));
    }
    this.dataProvider.selectSource(source, !this.dataProvider.isSelected(source));
    this.dataProvider.selectionUpdated.emit(this.dataProvider.getSelection());
    this.selectionChange.emit(source);
  }

  toggleSourceParent(source: TreeViewItem) {
    const selectedParent = this.dataProvider.getSelectedParent(source);
    if (selectedParent) {
      this.dataProvider.selectSource(selectedParent, false);
      selectedParent.subItems.filter(s => s !== source)
          .forEach(s => this.dataProvider.selectSource(s, true));
      this.toggleSourceParent(source);
    }
    return false;
  }

  sourceSelectionType(source: TreeViewItem): 'all' | 'partially' | 'none' {
    if (this.dataProvider.isSelected(source)) {
      return 'all';
    }
    if (this.isUnselected(source)) {
      return 'none';
    }
    return 'partially';
  }

  isUnselected(source: TreeViewItem): boolean {
    return SourcesProvider.flatMapSubItems(source).every(s => !this.dataProvider.isDirectlySelectedBy(s.info));
  }

  visibleSubItems = (parent: TreeViewItem) => parent.subItems?.filter(i => i.visible) || [];
}
