import {AfterViewInit, Component} from '@angular/core';
import {Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata-utils";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";
import {map, Observable} from "rxjs";
import {filterByTermArray, sort} from "../../../../common/utils";
import {AppContext} from "../../../../app-context";
import {AddOrganisationComponent, AddOrganisationComponentData} from "../add-organisation/add-organisation.component";

@Component({
    selector: 'app-organisations-overview',
    templateUrl: './organisations-overview.component.html',
    styleUrls: ['./organisations-overview.component.scss']
})
@Handler()
export class OrganisationsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;

    term: string;
    query: Observable<Organisation[]>;
    data: Organisation[] = [];

    ngAfterViewInit() {
        this.query = this.subscribeTo("getOrganisations")
            .pipe(map((o: Organisation[]) => o.filter(filterByTermArray([this.term]))))
            .pipe(map(c => sort(c, RefdataUtils.organisationComparator)));
    }

    executeQuery = () => {
        this.query.subscribe().unsubscribe();
    }

    trackByForRecord = (index: number, record: Organisation) => record.organisationId;

    addOrganisation = () => this.openModal(AddOrganisationComponent, <AddOrganisationComponentData>{
        organisation: {
            info: {},
            receivedAuthorisations: [],
            locations: [],
            givenAuthorisations: []
        }
    });
}
