import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {RefdataUtils} from "../../refdata-utils";
import {uuid} from "../../../../common/utils";
import {
  ContractPeriod,
  EmissionSource,
  ServiceProvider,
  UpsertContractPeriod
} from "@flowmaps/flowmaps-typescriptmodels";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";

@Component({
  selector: 'app-contract-period-details',
  templateUrl: './contract-period-details.component.html',
  styleUrls: ['./contract-period-details.component.scss']
})
@Handler()
export class ContractPeriodDetailsComponent extends View implements OnInit {
  dashboardContext = DashboardContext;
  refDataUtils = RefdataUtils;
  context = DashboardContext;

  @Input() data: ContractPeriodComponentData;
  isNewRecord: boolean;

  ngOnInit(): void {
    this.isNewRecord = !this.data.period || !this.data.period.periodId;
    if (this.isNewRecord) {
      this.data.period = {
        periodId: uuid(),
        data: {
          dateRange: {
            start: this.data.contract.contract.lastEndDate || '',
            end: ''
          },
          offPeakRate: null,
          rate: null,
          feedInRate: null,
          feedInOffPeakRate: null,
          standingCharge: null,
          source: this.data.contract.contract.lastSustainabilitySource || null,
          supplier: null
        },
      };
    }
  }

  standingChargeUnit = () => "p.m."
  costUnit = () => "€"

  updateContractPeriod = () => {
    this.sendCommand("com.flowmaps.api.organisation.UpsertContractPeriod", <UpsertContractPeriod> {
      contractId: this.data.contract.contract.contractId,
      organisationId: this.data.contract.organisation.organisationId,
      periodId: this.data.period.periodId,
      data: this.data.period.data
    });
  }
}

export interface ContractPeriodComponentData {
  period: ContractPeriod;
  contract: ContractEntity;
  source: EmissionSource;
  supplier: ServiceProvider;
}
