import {ComparatorChain} from "../../common/comparator-chain";
import {
    Address,
    Connection,
    ContractData,
    Location,
    LocationInfo,
    Organisation,
    OrganisationInfo,
    Role
} from "@flowmaps/flowmaps-typescriptmodels";
import {toTitleCase} from "../../common/utils";
import {ContractEntity} from "../../handlers/app-entity-handler.service";
import {Entity} from "../../handlers/entity";

export class RefdataUtils {

    static organisationComparator: ComparatorChain = new ComparatorChain('info.name', 'organisationId');
    static locationsComparator: ComparatorChain = new ComparatorChain('location.info.name', 'location.info.address.street', 'location.locationId');
    static connectionsComparator: ComparatorChain = new ComparatorChain('connection.info.code', 'connection.info.connectionType', 'location.info.name', 'location.info.address.street');
    static reportsComparator: ComparatorChain = new ComparatorChain('info.name', 'reportId');
    static locationEnergyCompassYearsComparator: ComparatorChain = new ComparatorChain('year');
    static alertsComparator: ComparatorChain = new ComparatorChain('alertStatusPriority', '-timestamp',
        'meter.connection.info.code', 'alertId');
    static errorsComparator: ComparatorChain = new ComparatorChain( '-lastSeen', 'errorId');
    static enumFormatter: (value: string) => string = v => toTitleCase(v).replace(/_/g, " ");

    static authorisationOptions: string[] = [Role.owner, Role.manager, Role.viewer];

    static organisationFormatter = (organisation: Organisation) =>
        organisation ? this.organisationInfoFormatter(organisation.info) : null;

    static contractsFormatter = (contract: ContractEntity) =>
        contract ? this.contractInfoFormatter(contract.contract.contractData) : null;

    static organisationInfoFormatter = (organisationInfo: OrganisationInfo) => organisationInfo?.name;

    static contractInfoFormatter = (contractData: ContractData) => contractData?.name;

    static locationInfoFormatter = (info: LocationInfo) => info ?
        `${info.name ? info.name + ' - ' : ''}${(this.addressFormatter(info.address))}` : null;

    static locationEntityFormatter = (loc: Entity) => loc ? this.locationsFormatter(loc.location) : null;

    static locationsFormatter = (loc: Location) => loc ?
        `${loc.info.name ? loc.info.name + ' - ' : ''} ${(this.addressFormatter(loc.info.address))}` : null;

    static addressFormatter = (address: Address, includeZipCode: boolean = true) => address
        ? includeZipCode
            ? `${address.street} ${address.number}${address.addition ? ' ' + address.addition : ''}, ${address.zipCode} ${address.city}`
            : `${address.street} ${address.number}${address.addition ? ' ' + address.addition : ''}, ${address.city}`
        : "";

    static connectionFormatter = (connection: Connection) => `${connection.info.label || connection.info.code}`;

    static meterFormatter = (m: Entity) => {
        const meterCode = m.meter.details?.label || m.meter.info.accessPointId;
        const surveyor = m.meter.info.surveyor?.name;
        return `${m.connection && m.connection.info.code !== meterCode ? m.connection.info.code + ' – ' : ''}${meterCode} (${surveyor === 'EDSN' ? 'KV' : surveyor})`.trim();
    }

    static getLocationArea = (location: Location): number => location?.info.area || location?.refdata?.area;
}