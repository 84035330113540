import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {
    AggregatedDataPoint,
    Connection,
    DataType,
    ExportConnectionData,
    Organisation,
    TimeResolution,
    UploadMeterData
} from "@flowmaps/flowmaps-typescriptmodels";
import {map, mergeMap, Observable} from "rxjs";
import * as XLSX from 'xlsx-js-style';
import {Entity} from "../../../../handlers/entity";
import {filterByTermArray, localTimeFormat, removeItem, sort} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {ConnectionComponentData, ConnectionDetailsComponent} from "../connection-details/connection-details.component";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {DateFieldRange} from "../../../../common/date/date-range/date-field-range";
import {SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {downloadConnectionsAsExcel, uploadConnectionsExcel} from "./connections-list-excel";
import {
    ArrayTemplate,
    Cell,
    DateTimeField,
    downloadWorkbook,
    exportDataAsExcel,
    exportExcelFromWorkBook,
    Field,
    getWorkbook,
    parseExcel,
    Parser,
    QuantityField,
    RequiredField,
    ValidatedField,
    WorkBookTemplate
} from "../../../../common/upload/excel.utils";
import {AppCommonUtils, sendQuery} from "../../../../common/app-common-utils";
import moment from "moment";
import {openConfirmationModalWithCallback} from "../../../../app-utils";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {downloadCsv} from "../../../../common/download.utils";
import lodash, {cloneDeep} from "lodash";
import {downloadBulkMonthData} from "./bulk-data-download";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {tap} from "rxjs/operators";
import {HandleCommand} from "../../../../common/handle-command";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";

@Component({
    selector: 'app-connections-overview',
    templateUrl: './connections-overview.component.html',
    styleUrls: ['./connections-overview.component.scss']
})
@Handler()
export class ConnectionsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;
    dashboardContext = DashboardContext;
    refDataUtils = RefdataUtils;
    organisation: Organisation;

    term: string;
    query: Observable<Entity[]>;
    data: Entity[] = [];
    selectedRecords: Entity[] = [];

    bulkDownloading: boolean;
    selecting: boolean;
    maxSelectableItems: number = 10;

    downloadConnectionsAsExcel = () => this.queryFilters(this.sendQuery("getConnectionsAsEntities")).subscribe(c => downloadConnectionsAsExcel(c));
    downloadAsEdsnCsv = () => this.queryFilters(this.sendQuery("getConnectionsAsEntities")).subscribe(c => downloadAsEdsnCsv(c));
    connectionCsvDataToJson = connectionCsvDataToJson;
    connectionDataTemplate = connectionDataExportTemplate;

    defaultDownloadRange = DashboardContext.defaultRange;
    bulkDownloadTimeRange: DateFieldRange = this.defaultDownloadRange();
    sourceProvider: SourcesProvider<any>;

    ngAfterViewInit() {
        this.createQuery();
        this.subscribeTo("getOrganisation").subscribe(o => {
            this.organisation = o;
            this.sourceProvider = new OrganisationProvider(this.organisation?.organisationId);
        });
    }

    createQuery = () => {
        this.query = this.queryFilters(this.subscribeTo("getConnectionsAsEntities"));
    }

    executeQuery = () => this.query.subscribe().unsubscribe();

    queryFilters = (o: Observable<Entity[]>): Observable<Entity[]> => {
        return o.pipe(map((entities: Entity[]) => entities.filter(value => filterByTermArray([this.term], value?.getExcludedFilterFields())(value))))
            .pipe(map(e => sort(e, RefdataUtils.connectionsComparator)));
    }

    trackByForRecord = (index: number, record: Entity) => record.connection.connectionId;

    createNewConnection = () => this.openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisation: this.organisation,
        locationId: null,
        connection: null
    });

    @HandleCommand("selectionChange")
    selectionChange(record: { entity: Entity, selected: boolean }) {
        if (record.selected) {
            if (this.maxSelectableItems && this.selectedRecords.length === this.maxSelectableItems) {
                AppCommonUtils.registerError("A maximum of 10 records is allowed to be selected");
                setTimeout(() => delete record.entity['selected'], 0);
                return;
            }
            if (!this.selectedRecords.includes(record.entity)) {
                this.selectedRecords.push(record.entity);
            }
        } else {
            this.selectedRecords = removeItem(this.selectedRecords, record.entity);
        }
        record['selected'] = record.selected;
    }

    toggleSelection = () => {
        this.selecting = !this.selecting;
        if (!this.selecting) {
            this.selectedRecords.forEach(c => delete c['selected']);
            this.selectedRecords = [];
        }
    }

    bulkDownloadMonthData(event: any) {
        downloadBulkMonthData(this.sourceProvider, this.bulkDownloadTimeRange);
    }

    downloadMeterAuthorisationPeriods = () => {
        let header = ["Organisation", "Location", "Building code", "EAN", "Market segment",
            "Connection Type", "Meter id", "Meter type", "Start", "End"];
        const data = [header];
        sendQuery("getOrganisations").subscribe(organisations => organisations.forEach(o => o.locations.forEach(l => l.connections.forEach((c : Connection) => c.meters.forEach(m => {
            let start = m.timeRange?.start;
            let end = c.info.desiredEndDate
            if (end && moment(end).diff(moment(), 'years') > 10) {
                end = null;
            }
            if (end && moment(end).isBefore(moment(start))) {
                start = m.info.authorizedFrom;
            }
            const entry: string[] = [
                RefdataUtils.organisationInfoFormatter(o.info),
                RefdataUtils.locationInfoFormatter(l.info),
                l.info.buildingCode,
                c.info.code,
                c.info.marketSegment,
                c.info.connectionType,
                m.meterId,
                m.info.type,
                start && moment(start).format('DD-MM-YYYY'),
                end && moment(end).format('DD-MM-YYYY')];
            data.push(entry);
        })))));
        exportDataAsExcel(data, 'meter-authorisation-periods.xlsx');
    }

    onUploadConnectionsList = (event: any) => {
        uploadConnectionsExcel(event.files[0]).subscribe(command => {
            if (command.updates.length === 0) {
                AppCommonUtils.registerError("No changes have been found between uploaded file and current data", 'warning', 3000);
                return;
            }
            const eanCodes = command.updates.map(c => c.info.code).map(ean => `<li>${ean}</li>`).join("");
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.organisation.UpsertConnections", command,
                        () => AppCommonUtils.registerSuccess('Connections were uploaded successfully'));
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "warning",
                title: "Bulk update connections",
                innerHtmlMessage: `<p><span>You are about to bulk update the following connections</span></p><ul class="notranslate fw-bold">${eanCodes}</ul><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Update connections",
                cancelText: "Cancel"
            }, 'static');
        });
        event.value = '';
    }

    onUploadConnectionData = (event: any) => {
        parseExcel(event.files[0], connectionDataImportTemplate)
            .subscribe(e => {
                sendQuery("getConnectionsAsEntities").subscribe(connections => {
                    const connectionWithData = connections.map(c => {
                        const dataPerEan = e.dataPerEan.find(r => r.ean === c.connection.info.code);
                        return dataPerEan ? {
                            connection: c,
                            dataPerEan: dataPerEan
                        } : null;
                    }).filter(c => c);
                    const command: UploadMeterData = {
                        connectionData: {}
                    };
                    connectionWithData.forEach(c => {
                        command.connectionData[c.connection.connection.connectionId] = getDataPointsOfConsumption(
                            e.year, this.getDataType(c.connection.connection), c.dataPerEan.consumptions);
                    });
                    this.sendCommand("com.flowmaps.api.measurements.UploadMeterData", command);
                });
            });

        event.value = '';


        function getDataPointsOfConsumption(year: number, dataType: DataType, consumptions: { [key: string]: number }):
            { [P in DataType]?: AggregatedDataPoint[] } {
            const returnValue: { [P in DataType]?: AggregatedDataPoint[] } = {};
            Object.entries(consumptions).map(e => {
                const startDate = moment(`01-${e[0]}-${year}`, "DD-MMMM-YYYY");
                return {
                    type: dataType,
                    value: e[1],
                    timeRange: {
                        start: `${startDate.format("YYYY-MM-DDTHH:mm:ss")}`,
                        end: `${startDate.add(1, "month").format("YYYY-MM-DDTHH:mm:ss")}`
                    }
                }
            }).filter(d => d.value).forEach(
                r => {
                    if (!returnValue[r.type]) {
                        returnValue[r.type] = [];
                    }
                    returnValue[r.type].push({
                        value: r.value,
                        timeRange: r.timeRange
                    });
                });
            return returnValue;
        }
    }

    uploadMinuteData = (event: any) => {
        getWorkbook(event.files[0])
            .pipe(mergeMap(workbook =>
                parseExcel(event.files[0], {
                    sheets: workbook.SheetNames.map(s => ({
                        name: s,
                        template: {
                            [s]: new ArrayTemplate(connectionDataExportTemplate, [2, parseInt(workbook.Sheets[s]["!ref"].match(/(\d+)$/)[0])])
                        }
                    }))
                })))
            .subscribe(data => {
                sendQuery("getConnectionsAsEntities").subscribe(connections => {
                    const connectionWithData = connections
                        .filter(c => data[c.connection.info.code])
                        .map(c => ({
                            connection: c,
                            dataPerEan: data[c.connection.info.code]
                        }));
                    const command: UploadMeterData = {
                        connectionData: {}
                    };
                    connectionWithData.forEach(c => addConnectionData(c, command));
                    this.sendCommand("com.flowmaps.api.measurements.UploadMeterData", command);
                });
            });

        let addConnectionData = (c: { dataPerEan: any; connection: Entity }, command: UploadMeterData) => {
            const consumptionDataType = this.getDataType(c.connection.connection);
            const productionDataType = this.getProductionDataType(c.connection.connection);
            const result: { [key: string]: AggregatedDataPoint[] } = {
                [consumptionDataType]: []
            };
            if (productionDataType) {
                result[productionDataType] = [];
            }
            c.dataPerEan.forEach(r => {
                if (r.consumption) {
                    result[consumptionDataType].push({
                        value: r.consumption,
                        timeRange: {
                            start: r.start,
                            end: r.end
                        }
                    });
                }
                if (productionDataType && r.feedIn) {
                    result[productionDataType].push({
                        value: r.feedIn,
                        timeRange: {
                            start: r.start,
                            end: r.end
                        }
                    });
                }
            });
            command.connectionData[c.connection.connection.connectionId] = result;
        }
    }

    private getDataType = (connection: Connection): DataType => {
        switch (connection.info.connectionType) {
            case "Electricity":
                return DataType.electricityConsumption;
            case "Gas":
                return DataType.gasConsumption;
            case "Heat":
                return DataType.heatConsumption;
            case "Water":
                return DataType.waterConsumption;
        }
    }

    private getProductionDataType = (connection: Connection): DataType => {
        switch (connection.info.connectionType) {
            case "Electricity":
                return DataType.electricityFeedIn;
            default:
                return null;
        }
    }
}

interface ConnectionData {
    connectionId: string;
    start: string;
    end: string;
    consumption?: number;
    feedIn?: number;
}

export function connectionCsvDataToJson(csvData: string): ConnectionData[] {
    const data = lodash.reject(csvData.split("\n"), lodash.isEmpty).slice(1);
    return data.map(r => {
        const values = r.split(";");
        const timeRange = values[1].split("|");
        return {
            connectionId: values[0],
            start: timeRange[0],
            end: timeRange[1],
            consumption: lodash.toNumber(values[2]),
            feedIn: lodash.toNumber(values[3])
        }
    });
}

export function downloadAsEdsnCsv(connections: Entity[]) {
    const array = [['EAN ODA', 'EAN Aansluiting', 'Begindatum', 'Einddatum', 'Klantnaam']];
    const now = moment();
    const start = now.clone().subtract(2, 'weeks').format('DD-MM-YYYY');
    const end = now.clone().endOf('year').add(1, 'years').add(15, 'days').format('DD-MM-YYYY');
    array.push(...connections.filter(c => c.connection.info.surveyor?.name === 'EDSN')
        .map(c => {
            const name = c.organisation.info.name.replace(',', '');
            return ['8719333037141', c.connection.info.code, start, end, name];
        }));
    downloadCsv(array, '8719333037141-' + now.format('DDMMYYYY'));
}

export function downloadRawConnectionData(timeRange: DateFieldRange, resolution: TimeResolution,
                                          selectedItems: Entity[], csvMapper: (csv: string) => any[], comparator: ComparatorChain, onDownloaded: () => void = null) {
    sendQuery("com.flowmaps.api.measurements.ExportConnectionData", <ExportConnectionData>{
        timeRange: {
            start: moment(timeRange.start).format(localTimeFormat),
            end: moment(timeRange.end).format(localTimeFormat)
        },
        resolution: resolution,
        ids: selectedItems.map(c => c.connection.connectionId)
    }, {
        showSpinner: true,
        responseType: "text"
    }).subscribe(csv => {
        downloadData(selectedItems.sort(comparator.compare), csvMapper(csv),
            connectionDataExportTemplate);
        if (onDownloaded) {
            onDownloaded();
        }
    })
}

function downloadData(connections: Entity[], data: any[], sheetTemplate: any) {
    const dataGrouped = lodash.groupBy(data, c => c.connectionId);
    const template: WorkBookTemplate = {
        sheets: connections.map(c => ({
            name: c.connection.info.code,
            template: {
                [c.connection.connectionId]: new ArrayTemplate(sheetTemplate,
                    [2, (dataGrouped[c.connection.connectionId] || []).length + 1])
            }
        }))
    };
    exportExcelFromWorkBook(downloadWorkbook("/assets/templates/connections-data-download.xlsx")
            .pipe(tap(wb => {
                return connections.forEach(c =>
                    XLSX.utils.book_append_sheet(wb, cloneDeep(wb.Sheets["Connections"]), c.connection.info.code));
            }))
            .pipe(tap(wb => XLSX.utils.book_set_sheet_visibility(wb, "Connections", 2))),
        template, dataGrouped, "connections-data-export.xlsx");
}

const connectionDataImportTemplate = {
    sheets: [
        {
            name: 'Data',
            template: {
                year: new RequiredField('B1'),
                dataPerEan: new ArrayTemplate({
                    ean: new RequiredField('A$'),
                    consumptions: {
                        january: new QuantityField('B$'),
                        february: new QuantityField('C$'),
                        march: new QuantityField('D$'),
                        april: new QuantityField('E$'),
                        may: new QuantityField('F$'),
                        june: new QuantityField('G$'),
                        july: new QuantityField('H$'),
                        august: new QuantityField('I$'),
                        september: new QuantityField('J$'),
                        october: new QuantityField('K$'),
                        november: new QuantityField('L$'),
                        december: new QuantityField('M$'),
                    }
                }, [3, 100])
            }
        }
    ]
}

const connectionDataExportTemplate = {
    start: new ValidatedField(new DateTimeField('A$'), (value: any, cell: Cell, parser: Parser, cellNameFunction) => {
        const start = moment(value);
        const end = moment(Field.getCellValue(parser.mapAny(connectionDataExportTemplate.end, cellNameFunction)));
        if (!start.isValid() || !end.isValid()) {
            throw "Start or end date is not a valid format";
        }
        if (start.isSameOrAfter(end)) {
            throw 'Start date cannot be the same or later then end date.';
        }
    }),
    end: new DateTimeField('B$'),
    consumption: new QuantityField('C$'),
    feedIn: new QuantityField('D$'),
}