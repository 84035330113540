import {Connection, Location, Meter, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../views/refdata/refdata-utils";

export class Entity {
    organisation: Organisation;
    location?: Location;
    connection?: Connection;
    meter?: Meter;
    searchAlias: string[];

    constructor(organisation: Organisation, location?: Location, connection?: Connection, meter?: Meter) {
        this.organisation = organisation;
        this.location = location;
        this.connection = connection;
        this.meter = meter;
        this.searchAlias = this.getSearchAlias();
    }

    getEntityType(): EntityType {
        return this.meter ? EntityType.meter
            : this.connection ? EntityType.connection
                : this.location ? EntityType.location
                    : EntityType.organisation;
    }

    getEntityId(): string {
        switch (this.getEntityType()) {
            case EntityType.organisation:
                return this.organisation.organisationId;
            case EntityType.location:
                return this.location.locationId;
            case EntityType.connection:
                return this.connection.connectionId;
            case EntityType.meter:
                return this.meter.meterId;
        }
    }

    getFormattedName(): string {
        switch (this.getEntityType()) {
            case EntityType.organisation:
                return RefdataUtils.organisationFormatter(this.organisation);
            case EntityType.location:
                return RefdataUtils.locationInfoFormatter(this.location.info);
            case EntityType.connection:
                return this.connection.info.code || this.connection.connectionId;
            case EntityType.meter:
                return RefdataUtils.meterFormatter(this);
        }
    }

    getParentAsEntity(): Entity {
        switch (this.getEntityType()) {
            case EntityType.organisation:
                return null;
            case EntityType.location:
                return new Entity(this.organisation);
            case EntityType.connection:
                return new Entity(this.organisation, this.location);
            case EntityType.meter:
                return new Entity(this.organisation, this.location, this.connection);
        }
    }

    getChildIds(): string[] {
        switch (this.getEntityType()) {
            case EntityType.organisation:
                return this.organisation.locations.map(l => l.locationId);
            case EntityType.location:
                return this.location.connections.map(c => c.connectionId);
            case EntityType.connection:
                return this.connection.meters.map(m => m.meterId);
            case EntityType.meter:
                return [];
        }
    }

    private getSearchAlias(): string[] {
        switch (this.getEntityType()) {
            case EntityType.organisation:
                return []
            case EntityType.location:
                const address = this.location.info?.address
                    ? RefdataUtils.addressFormatter(this.location.info.address, true) : null;
                return address ? [address, address.replace(",", "")] : [];
            case EntityType.connection:
                return []
            case EntityType.meter:
                return [];
        }
    }

    getExcludedFilterFields(excludedFields: string[] = []): string[] {
        return ["locations", "connections", "meters", "contracts", "timeZone"].concat(excludedFields);
    }
}

export enum EntityType {
    organisation = "organisation",
    location = "location",
    connection = "connection",
    meter = "meter"
}