import {Component, Input, OnInit} from '@angular/core';
import {BorderRadius, ChartConfiguration} from "chart.js";
import {BaseChart} from "../base-chart.component";
import moment, {unitOfTime} from "moment/moment";
import {DataType, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {getCSSVariableValue} from '../../../../chartjs/helpers';
import {localTimeFormat} from "../../../common/utils";
import {DashboardTime} from "../../../utils/measurements-data-provider";
import {BarAxesScalerPlugin} from "./bar-axes-scaler.plugin";
import {DateRangeUtils} from "../../../common/date/date-range/date-range.utils";
import {ActiveElement, Chart, ChartEvent} from "chart.js/dist/types";
import {BarAxesPercentagePlugin} from "./bar-axes-percentage.plugin";
import {AppContext} from "../../../app-context";
import lodash from "lodash";
import {TranslateDirective} from "../../../common/utils/translate.directive";


@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss'],
    providers: [{provide: BaseChart, useExisting: BarChartComponent}]
})
export class BarChartComponent extends BaseChart<'bar'> implements OnInit {

    possibleResolutions: TimeResolution[] = [
        TimeResolution.year,
        TimeResolution.month,
        TimeResolution.day,
        TimeResolution.hour,
        TimeResolution.minute
    ]

    @Input() stacked: boolean;
    @Input() showLegend: boolean;
    @Input() hideDefaultYAxis: boolean;
    @Input() y1AxisMeasurement: string;
    @Input() y2AxisMeasurement: string;
    @Input() powerAxisMeasurement: string;
    @Input() showNormalAxis: boolean;
    @Input() showCompleteness: boolean = false;
    @Input() animationDuration: number = 1000;
    @Input() selectedWeatherTypes: DataType[] = [];
    @Input() weatherTypes: DataType[] = [];
    @Input() horizontally: boolean;
    @Input() customOnClick: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean;
    @Input() customHover: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean;
    @Input() topPadding: number;
    @Input() skipPaddingX: boolean;

    barAxesScalerPlugin = new BarAxesScalerPlugin(this.chartUtils);
    barAxesPercentagePlugin = new BarAxesPercentagePlugin(this.chartUtils, ["completeness", "sunHours"]);

    ngOnInit() {
        this.barAxesScalerPlugin.scaleChanged.subscribe(scales =>
            this.ngZone.runOutsideAngular(() => this.refreshOptions()));
    }

    getOptions(): ChartConfiguration<'bar'>['options'] {
        const scales: any = this.getDefaultScales();
        this.addWeatherScales(scales);
        return {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: this.animationDuration
            },
            layout: {
                padding: {
                    top: this.topPadding || 0
                }
            },
            normalized: true,
            elements: {
                point: {
                    radius: 0
                },
                bar: {
                    borderRadius: this.borderRadius
                }
            },
            indexAxis: this.horizontally ? 'y': 'x',
            scales: scales,
            plugins: {
                datalabels: {
                    display: false
                },
                tooltip: {
                    mode: this.horizontally ? "y" : "index",
                    intersect: false,
                    enabled: false,
                    callbacks: {
                        title(tooltipItems: any[]): string | string[] | void {
                            return lodash.uniq(tooltipItems.map(t => t.dataset?.tooltipLabels ? t.dataset.tooltipLabels[t.dataIndex] || t.label : t.label));
                        }
                    }
                },
                legend: {
                    display: this.showLegend,
                    position: 'right'
                },
                title: {
                    display: true,
                    text: this.chartTitle,
                    font: {
                        size: 20
                    }
                },
                decimation: {
                    enabled: true,
                    algorithm: 'lttb',
                    samples: 500
                }
            },
            hover: {
                mode: "dataset"
            },
            onHover: this.customHover === false ? null : (this.customHover as any || this.onHover),
            onClick: this.customOnClick === false ? null : (this.customOnClick as any || this.zoomDataRange)
        }
    }

    private zoomDataRange = (evt: ChartEvent, activeEls: ActiveElement[], chart: Chart) => {
        const canZoomIn = this.measurementsProvider.info.resolution != "hour";
        const hoverOverBar = activeEls.length > 0;
        const canZoomOut = this.measurementsProvider.info.resolution !== TimeResolution.year;
        const points = chart.getElementsAtEventForMode(evt.native, 'nearest', {intersect: true}, true);
        const currentIndex = this.possibleResolutions.indexOf(this.measurementsProvider.info.resolution);
        const currentResolution = this.possibleResolutions[currentIndex];

        if (points.length > 0) {
            const newResolution = this.possibleResolutions[currentIndex + 1];

            if (canZoomIn && hoverOverBar) {
                const startTime = moment(this.measurementsProvider.info.timeRange.start).add(points[0].index, currentResolution);
                this.measurementsProvider.dashboardHistory.push({
                    start: this.measurementsProvider.info.timeRange.start,
                    end: this.measurementsProvider.info.timeRange.end,
                    resolution: this.measurementsProvider.info.resolution,
                    label: this.measurementsProvider.info.predefinedTimeRange
                });
                this.timeChanged({
                    resolution: newResolution,
                    start: startTime.startOf(currentResolution).format(localTimeFormat),
                    end: startTime.add(1, currentResolution).startOf(currentResolution).format(localTimeFormat),
                    label: null
                });
            }
        } else if (!hoverOverBar && canZoomOut) {
            this.zoomOut(currentIndex);
        }
    };

    private getDefaultScales() {
        return {
            x: {
                position: 'bottom',
                stacked: this.stacked,
                ticks: {
                    autoSkip: !this.skipPaddingX,
                    autoSkipPadding: this.skipPaddingX ? 0 : 50,
                    minRotation: this.skipPaddingX ? 45 : 0,
                    maxRotation: this.skipPaddingX ? 45 : 0
                },
                title: {
                    display: this.horizontally,
                    text: this.barAxesScalerPlugin.axisTitle(this.y1AxisMeasurement, "x")
                }
            },
            y1: {
                display: !this.hideDefaultYAxis && !!this.showNormalAxis,
                stacked: this.stacked,
                position: 'left',
                ticks: {
                    autoSkip: true,
                },
                title: {
                    display: !!this.y1AxisMeasurement,
                    text: this.barAxesScalerPlugin.axisTitle(this.y1AxisMeasurement, "y1")
                },
                grid: {
                    color: (context, options) => {
                        if (context.tick?.value === 0) {
                            return "#969696";
                        }
                        if (context.index % 2 === 0) {
                            return getCSSVariableValue('--bs-chart-grid-line-color');
                        }
                    },
                }
            },
            y1Aggregated: {
                display: !this.hideDefaultYAxis && !!!this.showNormalAxis && !this.horizontally,
                stacked: true,
                position: 'left',
                ticks: {
                    autoSkip: true,
                },
                title: {
                    display: !!this.y1AxisMeasurement,
                    text: this.barAxesScalerPlugin.axisTitle(this.y1AxisMeasurement, "y1Aggregated")
                },
                grid: {
                    color: (context, options) => {
                        if (context.tick?.value === 0) {
                            return "rgb(101,101,101)";
                        }
                        if (context.index % 2 === 0) {
                            return getCSSVariableValue('--bs-chart-grid-line-color');
                        }
                    },
                }
            },
            y2: this.getSecondYAxes(!!this.y2AxisMeasurement && this.selectedWeatherTypes.length === 0),
            y2Previous: this.getSecondPreviousYAxes(),
            power: {
                type: 'linear',
                position: 'right',
                display: !!this.powerAxisMeasurement,
                ticks: {
                    autoSkip: true,
                },
                grid: {
                    drawOnChartArea: false
                },
                title: {
                    display: true,
                    text: this.barAxesScalerPlugin.axisTitle(this.powerAxisMeasurement, "power")
                }
            },
            lines: {
                type: 'linear',
                position: 'left',
                display: this.chartData.datasets.some(d => d.yAxisID === "lines"),
                ticks: {
                    autoSkip: true,
                },
                grid: {
                    drawOnChartArea: true
                },
                title: {
                    display: true,
                    text: this.barAxesScalerPlugin.axisTitle(this.y1AxisMeasurement, "lines")
                }
            },
            completeness: {
                type: 'linear',
                position: 'right',
                display: !!this.showCompleteness && !this.powerAxisMeasurement && !this.y2AxisMeasurement && this.selectedWeatherTypes.length === 0,
                ticks: {
                    autoSkip: true,
                },
                suggestedMin: 0,
                suggestedMax: 1,
                grid: {
                    drawOnChartArea: false
                },
                title: {
                    display: true,
                    text: this.barAxesPercentagePlugin.axisTitle(TranslateDirective.getTranslation("Completeness", true))
                }
            },
        };
    }

    private getSecondPreviousYAxes(): any {
        return {
            type: 'linear',
            position: 'right',
            display: false,
            ticks: {
                autoSkip: true,
            },
        };
    }

    private getSecondYAxes(display: boolean): any {
        return {
            type: 'linear',
            position: 'right',
            display: display,
            grid: {
                drawOnChartArea: false
            },
            ticks: {
                autoSkip: true,
            },
            title: {
                display: true,
                text: this.barAxesScalerPlugin.axisTitle(this.y2AxisMeasurement, "y2")
            }
        };
    }

    private zoomOut(currentIndex: number) {
        if (this.measurementsProvider.dashboardHistory.length > 0) {
            this.timeChanged(this.measurementsProvider.dashboardHistory[this.measurementsProvider.dashboardHistory.length - 1]);
            this.measurementsProvider.dashboardHistory.pop();
            return;
        }

        const newResolution = this.possibleResolutions[currentIndex - 1];

        if (newResolution === TimeResolution.year) {
            const allSelectedTimeRange = this.measurementsProvider.getAllSelectedTimeRange();
            this.timeChanged({
                resolution: newResolution,
                start: allSelectedTimeRange.start,
                end: allSelectedTimeRange.end
            });
        } else {
            const timeResolution = this.possibleResolutions[currentIndex - 2] as unitOfTime.Base;
            this.timeChanged({
                resolution: newResolution,
                start: moment(this.measurementsProvider.info.timeRange.start).startOf(timeResolution).format(localTimeFormat),
                end: moment(this.measurementsProvider.info.timeRange.start).add(1, timeResolution)
                    .startOf(timeResolution).format(localTimeFormat)
            });
        }
    }

    private timeChanged(time: DashboardTime) {
        time.label = DateRangeUtils.getRangeLabel({
            start: moment(time.start),
            end: moment(time.end)
        }, this.measurementsProvider.ranges);
        if (this.directiveView) {
            this.directiveView.publishEvent("timeChanged", time);
        }
        this.ngZone.runOutsideAngular(() => this.measurementsProvider.timeChanged(time));
    }

    private addWeatherScales(scales: any) {
        this.weatherTypes.forEach(w => {
            const scale = this.getSecondYAxes(!!this.y2AxisMeasurement && this.selectedWeatherTypes.includes(w));
            if (w === DataType.sunHours) {
                scale.title.text = this.barAxesPercentagePlugin.axisTitle(AppContext.measurementName(w, true));
            }
            scales[`${w}`] = scale;
            scales[`${w}Previous`] = this.getSecondPreviousYAxes();
        })
    }

    borderRadius = (tick): BorderRadius => {
        if (this.horizontally) {
            if (tick.raw > 0) {
                return {
                    bottomLeft: 0,
                    bottomRight: 10,
                    topLeft: 0,
                    topRight: 10
                }
            } else {
                return {
                    bottomLeft: 10,
                    bottomRight: 0,
                    topLeft: 10,
                    topRight: 0
                }
            }
        } else {
            if (tick.raw > 0) {
                return {
                    bottomLeft: 0,
                    bottomRight: 0,
                    topLeft: 10,
                    topRight: 10
                }
            } else {
                return {
                    bottomLeft: 10,
                    bottomRight: 10,
                    topLeft: 0,
                    topRight: 0
                }
            }
        }
    }

    private onHover = (evt: ChartEvent, activeEls: ActiveElement[]) => {
        const target: any = evt.native.target;
        const canZoomIn = this.measurementsProvider.info.resolution != "hour";
        const canZoomOut = this.measurementsProvider.info.resolution !== TimeResolution.year;
        const hoverOverBar = activeEls.length > 0;
        if (hoverOverBar && canZoomIn) {
            target.style.cursor = "pointer";
        } else if (!hoverOverBar && canZoomOut) {
            target.style.cursor = "zoom-out";
        } else {
            target.style.cursor = "auto";
        }
    }
}